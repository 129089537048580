import React from 'react';
import Modal from 'lib/Modal';
import { commonStyles } from 'lib/commonStyles';
import Spinner from 'lib/Spinner';

import Gateway from '../web/gateway';
import { transl } from '../translations';

export const AddressChangePopup = ({
  closePopup,
  reloadOrders,
  transportOrderId,
}: {
  closePopup: () => any;
  reloadOrders: () => any;
  transportOrderId: string;
}): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const [recipientAddress, setRecipientAddress] = React.useState('');
  const [
    recipientPostalCode,
    setRecipientPostalCode,
  ] = React.useState('');

  const saveData = (): void => {
    setLoading(true);
    Gateway.post(
      `/admin/order/${transportOrderId}/update-order-address`,
      {
        data: {
          recipientAddress,
          recipientPostalCode,
        },
      }
    )
      .then(() => {
        setLoading(false);
        reloadOrders();
        closePopup();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: '500px',
      maxHeight: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslytgda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('ChangeOrderAddress')}</h2>
          <div className="form-group">
            <label htmlFor="changeOrderAddressPopup-recipientAddress">
              {transl('RecipientAddress')}
            </label>
            <input
              type="text"
              value={recipientAddress}
              className="form-control"
              id="changeOrderAddressPopup-recipientAddress"
              maxLength={100}
              onChange={(e): void =>
                setRecipientAddress(e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="changeOrderAddressPopup-recipientPostalCode">
              {transl('RecipientPostalCode')}
            </label>
            <input
              type="text"
              value={recipientPostalCode}
              className="form-control"
              id="changeOrderAddressPopup-recipientPostalCode"
              maxLength={100}
              onChange={(e): void =>
                setRecipientPostalCode(e.target.value)
              }
            />
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveData}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp;{transl('Save')}
            </button>
          </div>
        </div>
        <Spinner visible={loading} />
      </Modal>
    </div>
  );
};
