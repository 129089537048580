import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import Spinner from 'lib/Spinner';
import _ from 'lodash';

import {
  SpecialOrder,
  SpecialOrderWeeklySchedule,
  WeeklyScheduleItem,
  updateWeeklySchedule,
} from '../specialOrders/requests';
import { transl } from '../translations';
import { DayScheduleView } from './weeklySchedule/DayScheduleItem';
import { TransportCourier } from '../scheduledOrderEvents/types';

import Gateway from '../web/gateway';

const defaultSchedule: SpecialOrderWeeklySchedule = {
  mo: [],
  tu: [],
  we: [],
  th: [],
  fr: [],
  sa: [],
  su: [],
  exceptionDates: [],
  timezone: 'Europe/Helsinki',
};

type Day = 'mo' | 'tu' | 'we' | 'th' | 'fr' | 'sa' | 'su';

const days: Day[] = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];

export const WeeklyScheduleView = ({
  order,
  reloadOrders,
}: {
  order: SpecialOrder;
  reloadOrders: () => Promise<any>;
}): JSX.Element => {
  const [schedule, setSchedule] = React.useState({
    ...defaultSchedule,
    ...order.weeklySchedule,
  });
  const [couriers, setCouriers] = React.useState(
    [] as TransportCourier[]
  );
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    Gateway.get('/admin/transport-couriers').then((data) =>
      setCouriers(data.data)
    );
    return (): void => {
      // Intentionally empty
    };
  }, []);

  React.useEffect(() => {
    setSchedule({
      ...defaultSchedule,
      ...order.weeklySchedule,
    });
    return (): void => {
      // Intentionally empty
    };
  }, [order]);

  const addScheduleItem = (
    d: Day,
    item: WeeklyScheduleItem
  ): void => {
    let l = schedule[d] || [];
    l = l.filter(
      (it) =>
        it.orderCreationHour !== item.orderCreationHour ||
        it.orderCreationMinute !== item.orderCreationMinute
    );
    l.push(item);
    setSchedule({
      ...schedule,
      [d]: l,
    });
  };

  const removeScheduleItem = (
    d: Day,
    item: WeeklyScheduleItem
  ): void => {
    let l = schedule[d] || [];
    l = l.filter(
      (it) =>
        it.orderCreationHour !== item.orderCreationHour ||
        it.orderCreationMinute !== item.orderCreationMinute
    );
    setSchedule({
      ...schedule,
      [d]: l,
    });
  };

  const saveData = async (): Promise<void> => {
    setLoading(true);
    try {
      await updateWeeklySchedule(order.id ?? '', schedule);
      await reloadOrders();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <button
          type="button"
          className="btn btn-primary btn-lg"
          onClick={saveData}
          disabled={_.isEqual(schedule, order.weeklySchedule)}
        >
          &nbsp;{transl('Save')}
        </button>
      </div>
      {days.map((d) => {
        return (
          <DayScheduleView
            key={d}
            title={transl(d)}
            schedule={schedule[d] || []}
            addScheduleItem={(it): void => addScheduleItem(d, it)}
            removeScheduleItem={(it): void =>
              removeScheduleItem(d, it)
            }
            couriers={couriers}
          />
        );
      })}
      <Spinner visible={loading} />
    </div>
  );
};
