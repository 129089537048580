export const finvoiceBrokers = [
  { value: 'HELSFIHH', name: 'Aktia' },
  { value: 'AABAFI22', name: 'Ålandsbanken' },
  { value: '003723327487', name: 'Apix Messaging Oy' },
  { value: 'BAWCFI22', name: 'Basware Oyj' },
  { value: '5909000716438', name: 'Comarch' },
  { value: 'CREDIFLOW', name: 'Crediflow AB' },
  { value: 'DABAFIHH', name: 'Danske Bank' },
  { value: 'DNBAFIHX', name: 'DNB Bank ASA, Finland Branch' },
  { value: 'HANDFIHH', name: 'Handelsbanken' },
  { value: 'INEXCHANGE', name: 'InExchange Factorum AB' },
  { value: 'EXPSYS', name: 'Lexmark Expert Systems AB' },
  { value: '003721291126', name: 'Maventa' },
  { value: '003726044706', name: 'Netbox Finland Oy' },
  { value: 'NDEAFIHH', name: 'Nordea' },
  { value: '003717203971', name: 'Notebeat Oy' },
  { value: '003708599126', name: 'Open Text Oy' },
  { value: 'E204503', name: 'OpusCapita Solutions Oy' },
  { value: 'OKOYFIHH', name: 'Osuuspankki' },
  { value: '003723609900', name: 'Pagero' },
  { value: 'PEPPOL', name: 'PEPPOL' },
  { value: 'POPFFI22', name: 'POP Pankki' },
  { value: '003701150617', name: 'PostNord Strålfors Oy' },
  { value: '003714377140', name: 'Ropo Capital' },
  { value: 'ITELFIHH', name: 'Säästöpankki' },
  { value: 'SBANFIHH', name: 'S-Pankki/LähiTapiola' },
  { value: 'SPROOM', name: 'Sproom ApS' },
  { value: '003703575029', name: 'Telia / CGI' },
  { value: '003701011385', name: 'Tieto Oyj' },
];

export const oldBrokers = [
  {
    value: '00372332748700001',
    name: 'Apix Messaging Oy (vanha tunnus)',
  },
  {
    value: 'SE556700997101',
    name: 'InExchange Factorum AB (vanha tunnus)',
  },
];
