/* eslint-disable radix */
import React from 'react';
import moment from 'moment-timezone';
import DatePicker from 'lib/DatePicker';
import { transl } from '../translations';

export const TimeSelector = ({
  timestamp,
  setTimestamp,
}: {
  timestamp: string;
  setTimestamp: (tz: string) => any;
}): JSX.Element => {
  const timezone = transl('Timezone');

  return (
    <>
      <div className="form-group">
        <label>{transl('Date')}</label>
        <DatePicker
          selected={moment(timestamp)
            .locale(transl('Locale'))
            .tz(timezone)
            .toDate()}
          className="form-control"
          onChange={(val): void => {
            if (val) {
              setTimestamp(
                moment(val)
                  .locale(transl('Locale'))
                  .tz(timezone)
                  .set({
                    hour: moment(timestamp)
                      .locale(transl('Locale'))
                      .tz(timezone)
                      .hour(),
                    minute: moment(timestamp)
                      .locale(transl('Locale'))
                      .tz(timezone)
                      .minute(),
                    second: 0,
                    millisecond: 0,
                  })
                  .toISOString()
              );
            }
          }}
          dateFormat={'dd.MM.yyyy'}
        />
      </div>
      <div className="form-group">
        <label>{transl('DeliveryTimestamp')}</label>
        <input
          type="text"
          value={
            moment(timestamp)
              .locale(transl('Locale'))
              .tz(timezone)
              .hour() > 0
              ? moment(timestamp)
                  .locale(transl('Locale'))
                  .tz(timezone)
                  .hour()
                  .toString()
                  .padStart(2, '0')
              : ''
          }
          placeholder="00"
          className="form-control"
          onChange={(e): void => {
            let hour = parseInt(e.target.value, 10) || 0;
            if (hour < 0 || hour > 23) hour = 0;
            setTimestamp(
              moment(timestamp)
                .locale(transl('Locale'))
                .tz(timezone)
                .set({
                  hour,
                  second: 0,
                  millisecond: 0,
                })
                .toISOString()
            );
          }}
          style={{ width: '100px', display: 'inline' }}
        />{' '}
        :{' '}
        <input
          type="text"
          value={
            moment(timestamp)
              .locale(transl('Locale'))
              .tz(timezone)
              .minute() > 0
              ? moment(timestamp)
                  .locale(transl('Locale'))
                  .tz(timezone)
                  .minute()
                  .toString()
                  .padStart(2, '0')
              : ''
          }
          placeholder="00"
          className="form-control"
          onChange={(e): void => {
            let minute = parseInt(e.target.value, 10) || 0;
            if (minute < 0 || minute > 59) minute = 0;
            setTimestamp(
              moment(timestamp)
                .locale(transl('Locale'))
                .tz(timezone)
                .set({
                  minute,
                  second: 0,
                  millisecond: 0,
                })
                .toISOString()
            );
          }}
          style={{ width: '100px', display: 'inline' }}
        />
      </div>
    </>
  );
};
