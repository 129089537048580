export const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const IS_TEST_SERVER =
  window.location.hostname === 'localhost' ||
  window.location.pathname.includes('testing');

export const BASE_URL = IS_TEST_SERVER
  ? 'https://testing.cabapp.fi'
  : 'https://api.cabapp.fi';

fetch('https://api.cabapp.fi/sandbox/api/v1/pricing', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-provider-apikey':
      'sandbox:y704n35h34u8n58a08v95hunj890432qcmnjtru0',
  },
  body: JSON.stringify({
    data: {
      startAddress: 'Otaranta 8 B 55',
      startPostalCode: '02150',
      recipientAddress: 'Otsolahdentie 16 B 101',
      recipientPostalCode: '02110',
      city: 'helsinki',
    },
  }),
});
