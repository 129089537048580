import React from 'react';
import FixedTable from 'lib/FixedTable';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import { dynamoKeysToBase32 } from 'lib/helpers';
import { ProviderCompanyUserPopup } from './ProviderCompanyUserPopup';
import { ProviderCompanyUser } from './types';
import { transl } from '../translations';

import Gateway from '../web/gateway';

type ProviderCompanyUserListItemProps = {
  attrs: ProviderCompanyUser;
  reloadUsers: () => void;
  businessId: string;
  key: any;
};

const ProviderCompanyUserListItem = ({
  attrs,
  businessId,
  reloadUsers,
}: ProviderCompanyUserListItemProps): JSX.Element => {
  const toggleIsAdmin = (): void => {
    if (
      !window.confirm(
        transl(
          attrs.isAdmin ? 'ConfirmDemoteUser' : 'ConfirmPromoteUser'
        )
          .split('#EMAIL#')
          .join(attrs.email)
      )
    )
      return;
    Gateway.post(
      `/admin/providercompanies/businessId/${businessId}/users/id/${dynamoKeysToBase32(
        attrs.companyBusinessID,
        attrs.email
      )}`,
      { data: { isAdmin: !attrs.isAdmin } }
    ).then(reloadUsers);
  };
  const resetCredentials = (): void => {
    if (
      !window.confirm(
        transl('ConfirmResetPassword')
          .split('#EMAIL#')
          .join(attrs.email)
      )
    )
      return;
    Gateway.post(
      `/admin/providercompanies/businessId/${businessId}/users/id/${dynamoKeysToBase32(
        attrs.companyBusinessID,
        attrs.email
      )}/reset`,
      {}
    ).then(() =>
      alert(
        transl('UserCredentialsReset')
          .split('#EMAIL#')
          .join(attrs.email)
      )
    );
  };
  const deleteUser = (): void => {
    if (
      !window.confirm(
        transl('ConfirmRemoveUser').split('#EMAIL#').join(attrs.email)
      )
    )
      return;
    Gateway.del(
      `/admin/providercompanies/businessId/${businessId}/users/id/${dynamoKeysToBase32(
        attrs.companyBusinessID,
        attrs.email
      )}`
    ).then(() => {
      reloadUsers();
      alert(
        transl('UserCredentialsRemoved')
          .split('#EMAIL#')
          .join(attrs.email)
      );
    });
  };
  return (
    <tr style={{ cursor: 'pointer' }}>
      <td>{attrs.email}</td>
      <td>
        {attrs.isAdmin ? transl('Yes') : transl('No')}&nbsp;&nbsp;
        <button
          type="button"
          className="btn btn-primary btn-md"
          onClick={toggleIsAdmin}
        >
          &nbsp;&nbsp;{' '}
          {attrs.isAdmin
            ? transl('DemoteToNormalUser')
            : transl('PromoteToAdmin')}
        </button>
      </td>
      <td>
        <button
          type="button"
          className="btn btn-warning btn-md"
          onClick={resetCredentials}
        >
          &nbsp;&nbsp;{transl('ResetPassword')}
        </button>
      </td>
      <td>
        <button
          type="button"
          className="btn btn-danger btn-md"
          onClick={deleteUser}
        >
          &nbsp;&nbsp;{transl('DeleteUser')}
        </button>
      </td>
    </tr>
  );
};

export const ProviderCompanyUsersListView = ({
  businessId,
}: {
  businessId: string;
}): JSX.Element => {
  const [users, setUsers] = React.useState(
    null as ProviderCompanyUser[] | null
  );
  const [newUserPopupOpen, setNewUserPopupOpen] = React.useState(
    false
  );

  React.useEffect(() => {
    setUsers(null);
    Gateway.get(
      `/admin/providercompanies/businessId/${businessId}/users`
    ).then((data) => setUsers(data.data));
    return (): void => {
      // Intentionally empty
    };
  }, [businessId]);

  const reloadUsers = (): void => {
    setUsers(null);
    Gateway.get(
      `/admin/providercompanies/businessId/${businessId}/users`
    ).then((data) => setUsers(data.data));
  };

  const openNewUserPopup = (): void => {
    setNewUserPopupOpen(true);
  };

  const closeNewUserPopup = (): void => {
    setNewUserPopupOpen(false);
  };

  if (users === null) {
    return <LoadingComponent />;
  }
  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={openNewUserPopup}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;{transl('InviteNewUser')}
        </button>
      </div>
      <div style={commonStyles.defaultContainer}>
        <FixedTable
          header={
            <tr>
              <th>{transl('Email')}</th>
              <th>{transl('AdminRights')}</th>
              <th>{transl('ResetPassword')}</th>
              <th>{transl('DeleteUser')}</th>
            </tr>
          }
        >
          {users.map((attrs, i) => {
            return (
              <ProviderCompanyUserListItem
                key={i}
                attrs={attrs}
                businessId={businessId}
                reloadUsers={reloadUsers}
              />
            );
          })}
        </FixedTable>
      </div>
      {newUserPopupOpen && (
        <ProviderCompanyUserPopup
          closePopup={closeNewUserPopup}
          reloadUsers={reloadUsers}
          businessId={businessId}
        />
      )}
    </div>
  );
};
