import React from 'react';

export const LoadingComponent = (): JSX.Element => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      paddingTop: '100px'
    }}
  >
    <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
  </div>
);
