import React from 'react';
import Modal from 'lib/Modal';
import moment from 'lib/moment';
import { commonStyles } from 'lib/commonStyles';

import { transl } from '../translations';
import { ProviderCompanySelector } from '../providerCompanyUsers/ProviderCompanySelector';
import Spinner from 'lib/Spinner';
import { CourierSelector } from '../specialOrderSchedule/CourierSelector';
import Gateway from '../web/gateway';
import { TransportCourier } from '../scheduledOrderEvents/types';
import { TimeSelector } from './TimeSelector';

const parsePrice = (price: string): number => {
  const raw = parseFloat(price.split(',').join('.'));
  if (!raw) return 0;
  return Math.round(raw * 100);
};

export const NewCarTransferOrderPopup = ({
  closePopup,
  reloadOrders,
}: {
  closePopup: () => void;
  reloadOrders: () => void;
}): JSX.Element => {
  const [loading, setLoading] = React.useState(false);

  const [label, setLabel] = React.useState('');
  const [providerCompanyBusinessID, setProviderCompanyBusinessID] =
    React.useState(null as string | null);
  const [courierBase32, setCourierBase32] = React.useState(
    null as string | null
  );
  const [timestamp, settimestamp] = React.useState(
    moment().toISOString()
  );
  const [priceForProvider, setpriceForProvider] = React.useState('');
  const [priceForCourier, setpriceForCourier] = React.useState('');
  const [otherCosts, setotherCosts] = React.useState('');
  const [fromPostalCode, setfromPostalCode] = React.useState('');
  const [toPostalCode, settoPostalCode] = React.useState('');
  const [totalKm, settotalKm] = React.useState('');

  const [couriers, setCouriers] = React.useState(
    [] as TransportCourier[]
  );

  React.useEffect(() => {
    Gateway.get('/admin/transport-couriers').then((data) =>
      setCouriers(data.data)
    );
    return (): void => {
      // Intentionally empty
    };
  }, []);

  const isValid =
    !!label &&
    !!providerCompanyBusinessID &&
    !!courierBase32 &&
    !!parsePrice(priceForProvider) &&
    !!parsePrice(priceForCourier) &&
    !!otherCosts &&
    !!fromPostalCode &&
    !!toPostalCode &&
    !!parseFloat(totalKm.split(',').join('.'));

  const saveData = (): void => {
    if (!isValid) return;
    setLoading(true);
    Gateway.post(`/admin/cartransferorders`, {
      data: {
        label,
        providerCompanyBusinessID,
        courierBase32,
        timestamp,

        priceForProvider: parsePrice(priceForProvider),
        priceForCourier: parsePrice(priceForCourier),
        otherCosts: parsePrice(otherCosts),

        fromPostalCode,
        toPostalCode,
        totalKm: parseFloat(totalKm) ?? 0,
      },
    }).finally(() => {
      setLoading(false);
      reloadOrders();
      closePopup();
    });
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslytgda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('NewCarTransferOrder')}</h2>
          <div className="form-group">
            <label>{transl('ChooseMerchant')}</label>
            <ProviderCompanySelector
              onChange={setProviderCompanyBusinessID}
            />
          </div>
          <div className="form-group">
            <label>{transl('ChooseCourier')}</label>
            <CourierSelector
              couriers={couriers}
              onSelect={setCourierBase32}
            />
          </div>
          <TimeSelector
            timestamp={timestamp}
            setTimestamp={settimestamp}
          />
          <div className="form-group">
            <label htmlFor="newcartransfreorderpopup-label">
              {transl('Name')}
            </label>
            <input
              type="text"
              value={label}
              className="form-control"
              id="newcartransfreorderpopup-label"
              onChange={(e): void => setLabel(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="newcartransfreorderpopup-priceForProvider">
              {transl('PriceForMerchant')}
            </label>
            <input
              type="text"
              value={priceForProvider}
              className="form-control"
              id="newcartransfreorderpopup-priceForProvider"
              maxLength={100}
              onChange={(e): void =>
                setpriceForProvider(e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newcartransfreorderpopup-priceForCourier">
              {transl('PriceForCourier')}
            </label>
            <input
              type="text"
              value={priceForCourier}
              className="form-control"
              id="newcartransfreorderpopup-priceForCourier"
              maxLength={100}
              onChange={(e): void =>
                setpriceForCourier(e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newcartransfreorderpopup-otherCosts">
              {transl('OtherCosts')}
            </label>
            <input
              type="text"
              value={otherCosts}
              className="form-control"
              id="newcartransfreorderpopup-otherCosts"
              maxLength={100}
              onChange={(e): void => setotherCosts(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="newcartransfreorderpopup-fromPostalCode">
              {transl('StartAddressPostalCode')}
            </label>
            <input
              type="text"
              value={fromPostalCode}
              className="form-control"
              id="newcartransfreorderpopup-fromPostalCode"
              maxLength={100}
              onChange={(e): void =>
                setfromPostalCode(e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newcartransfreorderpopup-settoPostalCode">
              {transl('EndAddressPostalCode')}
            </label>
            <input
              type="text"
              value={toPostalCode}
              className="form-control"
              id="newcartransfreorderpopup-settoPostalCode"
              maxLength={100}
              onChange={(e): void => settoPostalCode(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="newcartransfreorderpopup-totalKm">
              {transl('DistanceInKm')}
            </label>
            <input
              type="text"
              value={totalKm}
              className="form-control"
              id="newcartransfreorderpopup-totalKm"
              maxLength={100}
              onChange={(e): void => settotalKm(e.target.value)}
            />
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              disabled={!isValid}
              onClick={saveData}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp;{transl('Save')}
            </button>
          </div>
        </div>
      </Modal>
      <Spinner visible={loading} />
    </div>
  );
};
