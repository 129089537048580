import { BASE_URL, BASE_HEADERS } from './constants';

let authtoken: string | null = null;

const getAuthHead = (): object => ({
  'x-admin-apikey': authtoken,
});

const getRequestHeaders = (): object => {
  return { ...BASE_HEADERS, ...getAuthHead() };
};

export const makeUnauthenticatedRequest = async (
  method: string,
  url: string,
  data?: any
): Promise<any> => {
  const meta: any = {
    method,
    headers: BASE_HEADERS,
  };
  if (!['GET', 'OPTIONS', 'DELETE'].includes(method))
    meta.body = JSON.stringify(data);

  const res = await fetch(BASE_URL + url, meta);
  if (!res.ok) {
    throw res;
  }
  return await res.json();
};

const makeRequest = async (
  method: string,
  url: string,
  data?: any
): Promise<any> => {
  let res: any;
  try {
    const meta: any = {
      method,
      headers: getRequestHeaders(),
    };
    if (!['GET', 'OPTIONS', 'DELETE'].includes(method))
      meta.body = JSON.stringify(data);

    if (!url.startsWith('/')) url = '/' + url;

    res = await fetch(BASE_URL + url, meta);
  } catch (error) {
    const exp = {
      status: 408,
    };
    throw exp;
  }
  if (res.ok) {
    try {
      const json = await res.json();

      return json;
    } catch (error) {
      return {};
    }
  } else if (res.status === 401 || res.status === 403) {
    localStorage.removeItem('authToken');
    window.location.reload(true);
  } else {
    throw res;
  }
};

const Gateway = {
  get: (url: string): Promise<any> => {
    return makeRequest('GET', url);
  },

  post: (url: string, data: any): Promise<any> => {
    return makeRequest('POST', url, data);
  },

  put: (url: string, data: any): Promise<any> => {
    return makeRequest('PUT', url, data);
  },

  patch: (url: string, data: any): Promise<any> => {
    return makeRequest('PATCH', url, data);
  },

  del: (url: string): Promise<any> => {
    return makeRequest('DELETE', url);
  },

  setAuthtoken: (token: string): void => {
    authtoken = token;
  },

  hasAuthToken: (): boolean => {
    return !!authtoken;
  },

  getAuthHeaders: (): any => {
    return getAuthHead();
  },
};

export default Gateway;
