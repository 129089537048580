/* eslint-disable radix */
import React from 'react';
import Modal from 'lib/Modal';
import Gateway from '../web/gateway';
import { commonStyles } from 'lib/commonStyles';

export const ProductionScriptsPopup = ({
  closePopup,
}: {
  closePopup: () => void;
}): JSX.Element => {
  const [script, setScript] = React.useState('');

  const saveData = (): void => {
    if (!script) {
      return;
    }
    Gateway.post('/admin/run-production-script', {
      data: { script },
    });

    closePopup();
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslfsdda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>Run a script in production</h2>
          <div className="form-group">
            <label htmlFor="productionscriptPopup-script">
              Script Name
            </label>
            <input
              type="text"
              value={script}
              className="form-control"
              id="productionscriptPopup-script"
              onChange={(e): void => setScript(e.target.value)}
            />
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveData}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp; Run Script
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
