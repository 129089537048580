import React from 'react';
import FixedTable from 'lib/FixedTable';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import { SpecialOrderPopup } from './SpecialOrderPopup';
import { SpecialOrder, listSpecialOrders } from './requests';
import { transl } from '../translations';
import Gateway from '../web/gateway';

const ScheduledOrderListItem = ({
  attrs,
  openPopup,
  companies,
}: {
  attrs: SpecialOrder;
  openPopup: (data: SpecialOrder) => void;
  key: any;
  companies: { [businessID: string]: string };
}): JSX.Element => (
  <tr
    onClick={(): void => openPopup(attrs)}
    style={{ cursor: 'pointer' }}
  >
    <td>{attrs.name}</td>
    <td>
      {attrs.providerCompanyBusinessID &&
        companies[attrs.providerCompanyBusinessID]}{' '}
      ({attrs.providerCompanyBusinessID})
    </td>
  </tr>
);

export const SpecialOrdersView = (): JSX.Element => {
  const [orders, setOrders] = React.useState(
    null as SpecialOrder[] | null
  );
  const [orderPopupData, setOrderPopupData] = React.useState(
    null as SpecialOrder | null
  );
  const [providerCompanies, setProviderCompanies] = React.useState(
    {} as { [businessID: string]: string }
  );

  React.useEffect(() => {
    listSpecialOrders().then(setOrders);
    Gateway.get('/admin/providercompanies').then((res) => {
      setProviderCompanies(
        res.data
          .map((company) => ({
            value: company.businessID as string,
            label: company.name as string,
          }))
          .reduce(
            (
              current: { [businessID: string]: string },
              next: { label: string; value: string }
            ) => {
              return { ...current, [next.value]: next.label };
            },
            {}
          )
      );
    });
    return (): void => {
      // Intentionally empty
    };
  }, []);

  const reloadOrders = (): void => {
    setOrders(null);
    listSpecialOrders().then(setOrders);
  };

  const openNewOrderPopup = (): void => {
    setOrderPopupData({
      name: '',
      providerCompanyBusinessID: null,
      courierPrice: 0,
      merchantPrice: 0,
      linkedScheduledOrderID: null,
      courierSaturdayExtra: 0,
      courierSundayExtra: 0,
      merchantSundayExtra: 0,
      weeklySchedule: {},
      weeklyTemplate: {},
      orderCreateFields: {
        allowLeaveAtDoor: false,
        backupAddress: null,
        backupAddressPostalCode: null,
        backupPhone: null,
        city: '',
        externalID: '',
        numPackages: 0,
        providerCompanyBusinessID: '',
        providerCompanyName: '',
        recipientAddress: '',
        recipientAddressNotes: '',
        recipientEmail: '',
        recipientName: '',
        recipientPhoneNo: '',
        recipientPostalCode: '',
        startAddress: '',
        startAddressNotes: '',
        startAddressPhone: '',
        startAddressTitle: '',
        startPostalCode: '',
        currency: 'EUR',
      },
      schedule: [],
      returnToStartAfterComplete: false,
      deliveryConfirmationCanBeSkipped: false,
      requirePhotoAtStartAndEnd: false,
      priorityOrder: false,
      noTrackingForRecipient: false,
      skipPincodeVerification: false,
      timezone: 'Europe/Helsinki',
      viaAddresses: null,
      myxlineUserID: null,
    });
  };

  const closeOrderPopup = (): void => {
    setOrderPopupData(null);
  };

  if (orders === null) {
    return <LoadingComponent />;
  }
  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={openNewOrderPopup}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;{transl('NewSpecialOrder')}
        </button>
      </div>
      <div style={commonStyles.defaultContainer}>
        <FixedTable
          header={
            <tr>
              <th>{transl('Name')}</th>
              <th>{transl('Merchant')}</th>
            </tr>
          }
        >
          {orders.map((attrs, i) => {
            return (
              <ScheduledOrderListItem
                key={i}
                openPopup={setOrderPopupData}
                attrs={attrs}
                companies={providerCompanies}
              />
            );
          })}
        </FixedTable>
      </div>
      {!!orderPopupData && (
        <SpecialOrderPopup
          closePopup={closeOrderPopup}
          reloadOrders={reloadOrders}
          attrs={orderPopupData}
        />
      )}
    </div>
  );
};
