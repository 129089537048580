import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import { ProviderCompanySelector } from './ProviderCompanySelector';
import { ProviderCompanyUsersListView } from './ProviderCompanyUsersListView';
import { transl } from '../translations';

export const ProviderCompanyUsersView = (): JSX.Element => {
  const [
    chosenCompanyBusinessId,
    setChosenCompanyBusinessId,
  ] = React.useState(null as string | null);

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <label>{transl('ChooseMerchant')}</label>
        <ProviderCompanySelector
          onChange={setChosenCompanyBusinessId}
        />
      </div>
      {chosenCompanyBusinessId !== null && (
        <ProviderCompanyUsersListView
          businessId={chosenCompanyBusinessId}
        />
      )}
    </div>
  );
};
