import React from 'react';
import FixedTable from 'lib/FixedTable';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import ProviderCompanyPopup from './ProviderCompanyPopup';
import { ProviderCompany } from './types';
import { transl } from '../translations';
import moment from 'lib/moment';
import { downloadStringAsFile } from '../providerCompanyHistory/stringToFile';

import Gateway from '../web/gateway';

type ProviderCompanyListItemProps = {
  attrs: ProviderCompany;
  openPopup: (data: ProviderCompany) => void;
  key: any;
};

const loadCompaniesAsCsv = async (): Promise<any> => {
  const companies: ProviderCompany[] = (
    await Gateway.get('/admin/providercompanies')
  ).data;
  const companiesWithHasOrders = companies.map(async (company) => {
    const orders = await Gateway.get(
      `/admin/providercompanies/businessId/${
        company.businessID
      }/history?start=${moment()
        .subtract(90, 'days')
        .format('YYYY-MM-DD')}&end=${moment().format('YYYY-MM-DD')}`
    );
    return {
      ...company,
      hasOrders: orders.data.length > 0,
    };
  });
  const data = await Promise.all(companiesWithHasOrders);
  const csvRows = data.map((company) =>
    [
      company.businessID,
      company.name,
      company.email,
      company.address,
      company.phone,
      company.hasOrders ? 'Kyllä' : 'Ei',
    ].join(';')
  );
  downloadStringAsFile('data.csv', csvRows.join('\n'));
};

const ProviderCompanyListItem = ({
  attrs,
  openPopup,
}: ProviderCompanyListItemProps): JSX.Element => (
  <tr
    onClick={(): void => openPopup(attrs)}
    style={{ cursor: 'pointer' }}
  >
    <td>{attrs.businessID}</td>
    <td>{attrs.name}</td>
    <td>{attrs.email}</td>
    <td>
      {attrs.address}, {attrs.postalCode}
    </td>
  </tr>
);

const ProviderCompaniesView = (): JSX.Element => {
  const [companies, setCompanies] = React.useState(
    null as ProviderCompany[] | null
  );
  const [companyPopupData, setCompanyPopupData] = React.useState(
    null as ProviderCompany | null
  );
  const [searchString, setSearchString] = React.useState('');
  const [companiesExported, setCompaniesExported] =
    React.useState(false);

  React.useEffect(() => {
    Gateway.get('/admin/providercompanies').then((data) =>
      setCompanies(data.data)
    );
    return (): void => {
      // Intentionally empty
    };
  }, []);

  const reloadCompanies = (): void => {
    Gateway.get('/admin/providercompanies').then((data) =>
      setCompanies(data.data)
    );
  };

  const openNewCompanyPopup = (): void => {
    setCompanyPopupData({
      businessID: '',
      name: '',
      email: '',
      phone: '',
      address: '',
      postalCode: '',
      city: '',
      country: 'FI',
      language: 'fi',
      notificationsEmail: null,
      eInvoiceAddress: null,
      eInvoiceBroker: null,
      invoicingPeriod: 'biweekly',
      invoiceBreakdownEmail: null,
      vatPercentage: 24,
      customerReference: null,
      termOfPayment: 7,
      pricingID: null,
      temperatureMonitoringRequired: false,
      pincodeVerificationRequired: false,
      monthlyPayment: null,
      useMXLBusDeliveries: false
    });
  };

  const closeCompanyPopup = (): void => {
    setCompanyPopupData(null);
  };

  if (companies === null) {
    return <LoadingComponent />;
  }

  const filterdCompanies = companies.filter((company) => {
    if (!searchString) return true;
    let companyStr =
      company.name.trim() +
      company.businessID.trim() +
      company.city.trim();
    companyStr = companyStr.toLowerCase();
    return companyStr.includes(searchString.trim().toLowerCase());
  });
  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }} className="form-inline">
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={openNewCompanyPopup}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;{transl('NewProviderCompany')}
        </button>
        &nbsp;&nbsp;&nbsp;
        <input
          style={{ minWidth: '400px' }}
          className="form-control"
          type="text"
          value={searchString}
          placeholder={transl('SearchCompany')}
          onChange={(e): void => setSearchString(e.target.value)}
        />
      </div>
      <div style={commonStyles.defaultContainer}>
        <FixedTable
          header={
            <tr>
              <th>{transl('BusinessID')}</th>
              <th>{transl('Name')}</th>
              <th>{transl('Email')}</th>
              <th>{transl('Address')}</th>
            </tr>
          }
        >
          {filterdCompanies.map((attrs, i) => {
            return (
              <ProviderCompanyListItem
                key={i}
                openPopup={setCompanyPopupData}
                attrs={attrs}
              />
            );
          })}
        </FixedTable>
      </div>
      <div style={{ paddingTop: '15px', width: '200px' }}>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={(): any => {
            setCompaniesExported(true);
            loadCompaniesAsCsv();
          }}
          disabled={companiesExported}
        >
          {transl('ExportCompaniesAsCsv')}
        </button>
      </div>
      {companyPopupData !== null && (
        <ProviderCompanyPopup
          closePopup={closeCompanyPopup}
          reloadCompanies={reloadCompanies}
          attrs={companyPopupData}
        />
      )}
    </div>
  );
};

export default ProviderCompaniesView;
