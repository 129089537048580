/* eslint-disable radix */
import React from 'react';
import _ from 'lodash';
import Modal from 'lib/Modal';
import Gateway from '../web/gateway';
import { commonStyles } from 'lib/commonStyles';
import { formatFinnishPhoneNo } from 'lib/helpers';
import { ProviderCompany } from './types';
import { transl } from '../translations';
import { finvoiceBrokers } from './finvoiceBrokers';
import {
  serializeSum,
  deserializeSum,
} from '../specialOrders/requests';

const ProviderCompanyPopup = ({
  closePopup,
  reloadCompanies,
  attrs,
}: {
  closePopup: () => void;
  reloadCompanies: () => void;
  attrs: ProviderCompany;
}): JSX.Element => {
  const [companyFields, changeCompanyFields] =
    React.useState<ProviderCompany>(
      _.omit(attrs, ['createdAt', 'updatedAt'])
    );
  const [pricingIDOptions, setPricingIDOptions] = React.useState<
    { label: string; value: string }[] | null
  >(null);
  const [monthlyPayment, setMonthlyPayment] = React.useState<string>(
    deserializeSum(attrs.monthlyPayment)
  );

  React.useEffect(() => {
    Gateway.get('/admin/pricing-ids').then((data) =>
      setPricingIDOptions(data.data)
    );
  }, []);

  const setVal = (
    key: string,
    val: string,
    nullable: boolean,
    isNumber = false
  ): void => {
    let value: any = val;
    if (!value && nullable) value = null;
    if (isNumber) value = parseInt(val) || 0;
    changeCompanyFields({
      ...companyFields,
      [key]: value,
    });
  };

  const setBoolVal = (key: string, value: boolean): void => {
    changeCompanyFields({
      ...companyFields,
      [key]: value,
    });
  };

  const isNew = attrs.businessID.length === 0;

  const saveData = (): void => {
    if (
      companyFields.eInvoiceAddress &&
      !companyFields.eInvoiceBroker
    ) {
      window.alert(
        transl('MustHaveBrokerAsWellIfHasEInvoiceAddress')
      );
      return;
    }
    if (isNew) {
      Gateway.post('/admin/providercompanies', {
        data: {
          ...companyFields,
          phone: formatFinnishPhoneNo(companyFields.phone),
          monthlyPayment: serializeSum(monthlyPayment) ?? null,
        },
      }).then(reloadCompanies);
    } else {
      Gateway.put(
        `/admin/providercompanies/businessId/${attrs.businessID}`,
        {
          data: {
            ...companyFields,
            phone: formatFinnishPhoneNo(companyFields.phone),
            monthlyPayment: serializeSum(monthlyPayment) ?? null,
          },
        }
      ).then(reloadCompanies);
    }

    closePopup();
  };

  const resetApiKey = (): void => {
    if (!isNew) {
      if (window.confirm(transl('WarningInvalidationOfAPIKey'))) {
        Gateway.post(
          `/admin/providercompanies/businessId/${attrs.businessID}/reset-api-key`,
          {}
        ).then(() => {
          reloadCompanies();
          alert(
            transl('APIKeyReset')
              .split('#COMPANY#')
              .join(`${attrs.name}(${attrs.businessID})`)
          );
        });
      }
    }
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          {isNew && <h2>{transl('NewProviderCompany')}</h2>}
          {!isNew && <h2>{transl('ModifyProviderCompany')}</h2>}
          <p className="text-danger">
            {transl('StarFieldsRequired')}
          </p>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-businessID">
              {transl('BusinessID')}*
            </label>
            <input
              type="text"
              value={companyFields.businessID}
              className="form-control"
              id="newprovidercompanypopup-businessID"
              maxLength={50}
              disabled={!isNew}
              onChange={(e): void =>
                setVal('businessID', e.target.value.trim(), false)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-name">
              {transl('Name')}*
            </label>
            <input
              type="text"
              value={companyFields.name}
              className="form-control"
              id="newprovidercompanypopup-name"
              maxLength={100}
              onChange={(e): void =>
                setVal('name', e.target.value, false)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-email">
              {transl('Email')}*
            </label>
            <input
              type="text"
              value={companyFields.email}
              className="form-control"
              id="newprovidercompanypopup-email"
              maxLength={200}
              onChange={(e): void =>
                setVal('email', e.target.value, false)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-phone">
              {transl('Phone')}*
            </label>
            <input
              type="text"
              value={companyFields.phone}
              className="form-control"
              id="newprovidercompanypopup-phone"
              maxLength={200}
              onChange={(e): void =>
                setVal('phone', e.target.value, false)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-address">
              {transl('Address')}*
            </label>
            <input
              type="text"
              value={companyFields.address}
              className="form-control"
              id="newprovidercompanypopup-address"
              maxLength={200}
              onChange={(e): void =>
                setVal('address', e.target.value, false)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-postalCode">
              {transl('PostalCode')}*
            </label>
            <input
              type="text"
              value={companyFields.postalCode}
              className="form-control"
              id="newprovidercompanypopup-postalCode"
              maxLength={50}
              onChange={(e): void =>
                setVal('postalCode', e.target.value, false)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-city">
              {transl('City')}*
            </label>
            <input
              type="text"
              value={companyFields.city}
              className="form-control"
              id="newprovidercompanypopup-city"
              onChange={(e): void =>
                setVal('city', e.target.value, false)
              }
              maxLength={50}
            />
          </div>
          <div className="form-group">
            <label htmlFor="newtransportcompanypopup-country">
              {transl('Country')}
            </label>
            <select
              value={companyFields.country}
              className="form-control"
              id="newtransportcompanypopup-country"
              onChange={(e): void =>
                setVal('country', e.target.value, false)
              }
            >
              <option value="FI">Suomi</option>
              <option value="SE">Sverige</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="newtransportcompanypopup-language">
              {transl('Language')}
            </label>
            <select
              value={companyFields.language}
              className="form-control"
              id="newtransportcompanypopup-language"
              onChange={(e): void =>
                setVal('language', e.target.value, false)
              }
            >
              <option value="fi">Suomi</option>
              <option value="en">English</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-notificationsEmail">
              {transl('EmailForAutomatedNotifications')}
            </label>
            <input
              type="text"
              value={companyFields.notificationsEmail || ''}
              className="form-control"
              id="newprovidercompanypopup-notificationsEmail"
              maxLength={50}
              onChange={(e): void =>
                setVal('notificationsEmail', e.target.value, true)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-eInvoiceAddress">
              {transl('EInvoiceAddress')}
            </label>
            <input
              type="text"
              value={companyFields.eInvoiceAddress || ''}
              className="form-control"
              id="newprovidercompanypopup-eInvoiceAddress"
              maxLength={50}
              onChange={(e): void =>
                setVal('eInvoiceAddress', e.target.value, true)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-eInvoiceBroker">
              {transl('EInvoiceBroker')}
            </label>
            <select
              value={companyFields.eInvoiceBroker || ''}
              className="form-control"
              id="newprovidercompanypopup-eInvoiceBroker"
              onChange={(e): void =>
                setVal('eInvoiceBroker', e.target.value, true)
              }
            >
              <option value=""></option>
              {finvoiceBrokers.map((item, i) => (
                <option key={i} value={item.value}>
                  {item.name} ({item.value})
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-invoiceBreakdownEmail">
              {transl('EmailForInvoiceBreakDowns')}
            </label>
            <input
              type="text"
              value={companyFields.invoiceBreakdownEmail || ''}
              className="form-control"
              id="newprovidercompanypopup-invoiceBreakdownEmail"
              maxLength={50}
              onChange={(e): void =>
                setVal('invoiceBreakdownEmail', e.target.value, true)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newtransportcompanypopup-invoicingPeriod">
              {transl('InvoicingInterval')}*
            </label>
            <select
              value={companyFields.invoicingPeriod}
              className="form-control"
              id="newtransportcompanypopup-invoicingPeriod"
              onChange={(e): void =>
                setVal('invoicingPeriod', e.target.value, false)
              }
            >
              <option value="biweekly">{transl('Biweekly')}</option>
              <option value="monthly">{transl('Monthly')}</option>
              <option value="twicemonthly">
                {transl('TwiceMonthly')}
              </option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-vatPercentage">
              {transl('VatPercentage')}
            </label>
            <input
              type="text"
              value={companyFields.vatPercentage.toString() || ''}
              className="form-control"
              id="newprovidercompanypopup-vatPercentage"
              maxLength={50}
              placeholder={'0'}
              onChange={(e): void =>
                setVal('vatPercentage', e.target.value, false, true)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-termOfPayment">
              {transl('TermOfPayment')}
            </label>
            <input
              type="text"
              value={companyFields.termOfPayment.toString() || ''}
              className="form-control"
              id="newprovidercompanypopup-termOfPayment"
              maxLength={50}
              placeholder={'0'}
              onChange={(e): void =>
                setVal('termOfPayment', e.target.value, false, true)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-customerReference">
              {transl('CustomerReference')}
            </label>
            <input
              type="text"
              value={companyFields.customerReference || ''}
              className="form-control"
              id="newprovidercompanypopup-customerReference"
              onChange={(e): void =>
                setVal('customerReference', e.target.value, true)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newtransportcompanypopup-language">
              {transl('Pricing')}
            </label>
            {pricingIDOptions !== null && (
              <select
                value={companyFields.pricingID || 'null'}
                className="form-control"
                id="newtransportcompanypopup-language"
                onChange={(e): void =>
                  setVal(
                    'pricingID',
                    e.target.value === 'null' ? '' : e.target.value,
                    true
                  )
                }
              >
                <option value="null">{transl('OtherPricing')}</option>
                <>
                  {pricingIDOptions.map((item) => (
                    <option value={item.value} key={item.value}>
                      {item.label}
                    </option>
                  ))}
                </>
              </select>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="newprovidercompanypopup-monthlyPayment">
              {transl('MonthlyPayment')}
            </label>
            <input
              type="text"
              value={monthlyPayment}
              className="form-control"
              id="newprovidercompanypopup-monthlyPayment"
              onChange={(e): void =>
                setMonthlyPayment(e.target.value.trim())
              }
            />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={companyFields.pincodeVerificationRequired}
                onChange={(e): void =>
                  setBoolVal(
                    'pincodeVerificationRequired',
                    (e.target as any).checked
                  )
                }
              />
              &nbsp;&nbsp;
              {transl('PinCodeVerificationRequiredForAllOrders')}
            </label>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={companyFields.temperatureMonitoringRequired}
                onChange={(e): void =>
                  setBoolVal(
                    'temperatureMonitoringRequired',
                    (e.target as any).checked
                  )
                }
              />
              &nbsp;&nbsp;
              {transl('TemperatureMonitoringRequiredForAllOrders')}
            </label>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={companyFields.useMXLBusDeliveries ?? false}
                onChange={(e): void =>
                  setBoolVal(
                    'useMXLBusDeliveries',
                    (e.target as any).checked
                  )
                }
              />
              &nbsp;&nbsp;
              {transl('UseMXLBusDeliveries')}
            </label>
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveData}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp;{transl('Save')}
            </button>
            {!isNew && (
              <button
                style={{ marginTop: '30px' }}
                type="button"
                className="btn btn-danger btn-lg btn-block"
                onClick={resetApiKey}
              >
                &nbsp;&nbsp;{transl('ResetAPIKey')}
              </button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProviderCompanyPopup;
