import React from 'react';
import moment from 'moment';
import DatePicker from 'lib/DatePicker';

import { ScheduleItem } from '../../specialOrders/requests';
import { transl } from '../../translations';
import { TransportCourier } from '../../scheduledOrderEvents/types';
import { CourierSelector } from '../CourierSelector';

export const AddScheduleItemForm = ({
  couriers,
  addScheduleItem,
}: {
  addScheduleItem: (item: ScheduleItem) => any;
  couriers: TransportCourier[];
}): JSX.Element => {
  const [courierBase32, setCourierBase32] = React.useState(
    null as string | null
  );
  const [orderCreationTimestamp, setOrderCreationTimestamp] =
    React.useState(
      moment()
        .set({
          second: 0,
          millisecond: 0,
        })
        .toISOString()
    );
  const [
    timeUntilEarliestPickupTimeMinutes,
    setTimeUntilEarliestPickupTimeMinutes,
  ] = React.useState('');

  const savePressed = (): void => {
    addScheduleItem({
      courierBase32,
      orderCreationTimestamp,
      timeUntilEarliestPickupTimeMinutes:
        parseInt(timeUntilEarliestPickupTimeMinutes, 10) || null,
      timeToPickupMinutes: null,
    });
    setTimeUntilEarliestPickupTimeMinutes('');
    setOrderCreationTimestamp(
      moment()
        .set({
          second: 0,
          millisecond: 0,
        })
        .toISOString()
    );
  };

  return (
    <div style={{ paddingTop: '15px' }}>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>{transl('OrderCreateTime')}</th>
            <th></th>
            <th>{transl('OrderAllocationMinutes')}</th>
            <th>{transl('Courier')}</th>
            <th>{transl('Add')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <DatePicker
                selected={moment(orderCreationTimestamp).toDate()}
                className="form-control"
                onChange={(val): void => {
                  if (val)
                    setOrderCreationTimestamp(
                      moment(val)
                        .set({
                          hour: moment(orderCreationTimestamp).hour(),
                          minute: moment(
                            orderCreationTimestamp
                          ).minute(),
                          second: 0,
                          millisecond: 0,
                        })
                        .toISOString()
                    );
                }}
                dateFormat={'dd.MM.yyyy'}
              />
            </td>
            <td style={{ width: '250px' }}>
              <input
                type="text"
                value={
                  moment(orderCreationTimestamp).hour() > 0
                    ? moment(orderCreationTimestamp)
                        .hour()
                        .toString()
                        .padStart(2, '0')
                    : ''
                }
                placeholder="00"
                className="form-control"
                onChange={(e): void => {
                  let hour = parseInt(e.target.value, 10) || 0;
                  if (hour < 0 || hour > 23) hour = 0;
                  setOrderCreationTimestamp(
                    moment(orderCreationTimestamp)
                      .set({
                        hour,
                        second: 0,
                        millisecond: 0,
                      })
                      .toISOString()
                  );
                }}
                style={{ width: '100px', display: 'inline' }}
              />{' '}
              :{' '}
              <input
                type="text"
                value={
                  moment(orderCreationTimestamp).minute() > 0
                    ? moment(orderCreationTimestamp)
                        .minute()
                        .toString()
                        .padStart(2, '0')
                    : ''
                }
                placeholder="00"
                className="form-control"
                onChange={(e): void => {
                  let minute = parseInt(e.target.value, 10) || 0;
                  if (minute < 0 || minute > 59) minute = 0;
                  setOrderCreationTimestamp(
                    moment(orderCreationTimestamp)
                      .set({
                        minute,
                        second: 0,
                        millisecond: 0,
                      })
                      .toISOString()
                  );
                }}
                style={{ width: '100px', display: 'inline' }}
              />
            </td>
            <td>
              <input
                type="text"
                value={timeUntilEarliestPickupTimeMinutes}
                className="form-control"
                id="productionscriptPopup-script"
                onChange={(e): void =>
                  setTimeUntilEarliestPickupTimeMinutes(
                    e.target.value
                  )
                }
                style={{ width: '100px' }}
              />
            </td>
            <td style={{ width: '300px' }}>
              <CourierSelector
                couriers={couriers}
                onSelect={setCourierBase32}
              />
            </td>
            <td>
              <button
                type="button"
                className="btn btn-primary btn-md"
                onClick={savePressed}
              >
                &nbsp;&nbsp;{transl('Add')}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
