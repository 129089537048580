import React from 'react';
import LoginView from './LoginView';
import MainView from './MainView';
import Gateway from './web/gateway';

const saveAuthToken = (token: string): void => {
  localStorage.setItem('authToken', token);
};

const App = (): JSX.Element => {
  const [loggedIn, changeLoginStatus] = React.useState(
    Gateway.hasAuthToken()
  );

  const logUserIn = (authToken: string): void => {
    Gateway.setAuthtoken(authToken);
    saveAuthToken(authToken);
    changeLoginStatus(true);
  };

  if (!loggedIn) {
    return <LoginView logUserIn={logUserIn} />;
  }
  return <MainView />;
};

export default App;
