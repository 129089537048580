const fi: any = {
  DecimalSeparator: ',',
  LoginToAdminPanel: 'Kirjaudu CabApp Admin Paneeliin',
  LoginUsingGoogle: 'Kirjaudu Google-tunnuksilla',
  Merchants: 'Kauppiasyritykset',
  MerchantUsers: 'Kauppiaiden käyttäjätunnukset',
  TransportCompanies: 'Kuljetusyritykset',
  TransportCompanyAdmins: 'Kuljetusyritysten pääkäyttäjät',
  AdminPanel: 'CabApp Admin',
  TestEnv: 'Testiympäristö',
  ProdEnv: 'Tuotantoympäristö',
  Logout: 'Kirjaudu ulos',
  NewProviderCompany: 'Uusi kauppiasyritys',
  BusinessID: 'Y-tunnus',
  Name: 'Nimi',
  Email: 'Sähköposti',
  Address: 'Osoite',
  WarningInvalidationOfAPIKey:
    'VAROITUS! Tämä toiminto poistaa yrityksen aikaisemman API Avaimen käytöstä ja lähettää uuden yrityksen sähköpostiin. Jatka vain jos tiedät mitä olet tekemässä. Haluatko varmasti jatkaa?',
  APIKeyReset:
    'Yrityksen #COMPANY# API Avain resetoitiin onnistuneesti',
  ModifyProviderCompany: 'Muokkaa kauppiasyritystä',
  Phone: 'Puhelinnumero',
  PostalCode: 'Postinumero',
  City: 'Postitoimipaikka',
  EmailForAutomatedNotifications:
    'Sähköposti automaattisille ilmoituksille',
  EInvoiceAddress: 'Verkkolaskuosoite',
  Save: 'Tallenna',
  ResetAPIKey: 'Resetoi API Avain',
  ConfirmDemoteUser:
    'Haluatko varmasti poistaa käyttäjän #EMAIL# admin-oikeudet?',
  ConfirmPromoteUser:
    'Haluatko varmasti antaa admin-oikeudet käyttäjälle #EMAIL#?',
  ConfirmResetPassword:
    'Haluatko varmasti lähettää käyttäjälle #EMAIL# uuden salasanan?',
  UserCredentialsReset:
    'Käyttäjälle #EMAIL# on nyt lähetetty uusi salasana sähköpostitse',
  UserCredentialsRemoved:
    'Käyttäjän #EMAIL# kirjautumistunnukset on nyt poistettu lopullisesti',
  ConfirmRemoveUser:
    'Haluatko varmasti poistaa käyttäjän kirjautumistunnukset #EMAIL# lopullisesti?',
  Yes: 'Kyllä',
  No: 'Ei',
  DemoteToNormalUser: 'Poista admin-oikeudet',
  PromoteToAdmin: 'Anna admin-oikeudet',
  ResetPassword: 'Lähetä uusi salasana',
  DeleteUser: 'Poista kirjautumistunnukset',
  InviteNewUser: 'Kutsu uusi käyttäjä',
  AdminRights: 'Admin-oikeudet',
  SendInvite: 'Lähetä kirjautumistunnukset',
  NewTransportCompany: 'Uusi kuljetusyritys',
  ModifyTransportCompany: 'Muokkaa kuljetusyritystä',
  Language: 'Kieli',
  SupportPhoneNumber: 'Kuriirituen puhelinnumero',
  CustomerServicePhone: 'Asiakaspalvelun puhelinnumero',
  SupportEmail: 'Asiakaspalvelun sähköpostiosoite',
  AlertsEmail: 'Hälytysten sähköpostiosoite',
  AllowAdministration: 'CabApp yritys',
  ChooseMerchant: 'Valitse kauppiasyritys',
  InviteNewCompanyAmin: 'Kutsu uusi pääkäyttäjä',
  ChooseTransportCompany: 'Valitse kuljetusyritys',
  CompanyEmail: 'Yrityksen sähköposti',
  ScheduledOrders: 'Säännölliset tilaukset',
  NewScheduledOrder: 'Uusi säännöllinen tilaus',
  ModifyScheduledOrder: 'Muokkaa säännöllistä tilausta',
  ProviderCompanyBusinessID: 'Kauppiasyrityksen Y-tunnus',
  PriceForCourier: 'Hinta Kuskille',
  StartAddressPostalCode: 'Lähtöpaikan postinumero',
  EndAddressPostalCode: 'Päämääräosoitteen postinumero',
  DistanceInKm: 'Etäisyys (km)',
  Label: 'Selite',
  UnitPrice: 'Yksikköhinta',
  SundayExtra: 'Sunnuntailisä (ei sis. alv)',
  InvoiceRows: 'Laskurivit',
  InvoiceData: 'Laskutustiedot',
  ScheduledOrderEvents: 'Kuljettajakohtaiset säännölliset tilaukset',
  Amount: 'Määrä',
  PriceWithoutVat: 'Hinta (ei sis. alv)',
  PriceForMerchant: 'Hinta lähettäjälle (ei sis. alv)',
  Invoicing: 'Laskutus',
  CreateNewInvoices: 'Luo laskuja',
  Payperiod: 'Laskutuskausi',
  EInvoiceBroker: 'Verkkolaskuoperaattori',
  EmailForInvoiceBreakDowns: 'Sähköposti laskuerittelylle',
  InvoicingInterval: 'Laskutusväli',
  Biweekly: 'Joka toinen viikko',
  Monthly: 'Kuukausittain',
  TwiceMonthly: 'Kahdesti kuussa',
  ListInvoices: 'Tarkastele laskuja',
  InvoicesSuccessfullyCreated: 'Laskut on nyt onnistuneesti luotu',
  InvoiceCreationUnsuccessful: 'Laskujen luominen epäonnistui',
  ConfirmCreateInvoices:
    'Vahvista laskujen ja kuljettajien maksujen luominen ja lähettäminen',
  CreateInvoices: 'Luo ja lähetä laskut',
  CreateInvoicesForThisDay:
    'Luo ja lähetä tämän päivän laskut ja kuljettajamaksut',
  VatPercentage: 'ALV %',
  CustomerReference: 'Asiakkaan viite',
  TermOfPayment: 'Maksuaika (vrk)',
  CreateOrderRetroactively: 'Luo tilaus aiemmalle päivälle',
  SenderBusinessID: 'Lähettäjän Y-tunnus',
  SenderCompanyName: 'Lähettäjän yritysnimi',
  OrderNumber: 'Tilausnumero',
  StartAddressTitle: 'Noutopaikka',
  StartAddress: 'Nouto-osoite',
  StartPostalCode: 'Noutopaikan postinumero',
  StartAddressPhone: 'Noutopaikan puhelinnumero',
  StartAddressNotes: 'Saapumisohjeet noutopaikkaan',
  RecipientName: 'Vastaanottaja',
  RecipientAddress: 'Toimitusosoite',
  RecipientPostalCode: 'Toimitusosoitteen postinumero',
  RecipientPhoneNo: 'Vastaanottajan puhelinnumero',
  RecipientEmail: 'Vastaanottajan sähköposti',
  RecipientAddressNotes: 'Saapumisohjeet toimituspaikkaan',
  CourierCompanyBusinessId: 'Kuljettajan Y-tunnus',
  CourierEmail: 'Kuljettajan sähköposti',
  OrderTimestamp: 'Toimituksen aikaleima (ISO-8601)',
  WriteSearchDates: 'Syötä aikaväli',
  DoSearch: 'Tee haku',
  Date: 'Päiväys',
  NumberOfOrders: 'Tilauksia',
  PaymentFromMerchant: 'Laskutettavaa',
  PaymentToCourier: 'Maksettavaa',
  Profit: 'Kate',
  OrderStats: 'Toimitukset & tilastot',
  NumberOfScheludedOrderEvents: 'Säännöllisiä kuljetuksia',
  NoInvoicesToCreateAtThisTime:
    'Ei luotavia laskuja tai kuljettajamaksuja tällä hetkellä',
  ConfirmAllOrdersAreChecked:
    'Kaikki tähän päivään mennessä tapahtuneet säännölliset kuljetukset ja tilaukset on merkattu järjestelmään ja ne ovat oikein.',
  NewCourierPool: 'Uusi kuljettajaryhmä',
  CourierPools: 'Kuljettajaryhmät',
  ModifyCourierPool: 'Muokkaa kuljettajaryhmää',
  AddCourierToGroup: 'Lisää kuljettajaryhmään',
  Remove: 'Poista',
  CourierPool: 'Kuljettajaryhmä',
  AllCouriers: 'Kaikki kuljettajat',
  PhoneForNotifications: 'Puhelinnumero ilmoituksille',
  State: 'Tila',
  OrderPlacedTimestamp: 'Tilaus jätetty',
  PickupAddress: 'Nouto-osoite',
  EarliestPickupTime: 'Nouto aikaisintaan',
  Immediately: 'HETI',
  orderPlaced: 'Odottaa kuljetusta',
  courierChosen: 'Kuljettaja valittu',
  pickedUp: 'Kuljetuksessa',
  delivered: 'Luovutettu vastaanottajalle',
  pickupFailed: 'Nouto epäonnistui',
  deliveryFailed: 'Toimitus epäonnistui',
  cancelled: 'Peruutettu',
  ProviderCompanyHistory: 'Asiakaskohtainen tilaushistoria',
  DownloadAsCsv: 'Vie csv-tiedostoon',
  PinCodeVerificationRequiredForAllOrders:
    'Varmenna toimitukset PIN-koodilla (Apteekkitoimitukset ja arvokkaat toimitukset)',
  TemperatureMonitoringRequiredForAllOrders:
    'Lämpötilaseuranta vaaditaan (Apteekkitoimitukset)',
  Pricing: 'Hinnasto',
  OtherPricing: 'Muu Hinnasto',
  FlowerPricing: 'Kukkatoimitukset',
  PharmacyPricing: 'Apteekkitoimitukset',
  SkipAutomatedPayments:
    'Älä luo automaattisia maksukuitteja tälle yritykselle',
  MarkAsScheduledOrderEvent: 'Merkitse keikka reitille',
  ChooseScheduledOrder: 'Valitse reitti',
  AllFieldsRequired: 'Kaikki kentät vaaditaan',
  StarFieldsRequired: 'Tähdellä (*) merkityt kentät ovat pakollisia',
  ErrorSavingSpecialOrder:
    'Muutosten tallentaminen epäonnistui. Tarkista ettei osoitteessa ole kirjoitusvirheitä ja että kaikki tähdellä (*) merkityt kentät on täytetty.',
  SpecialOrders: 'Sopimuskuljetukset',
  NewSpecialOrder: 'Uusi sopimuskuljetus',
  ModifySpecialOrder: 'Muokkaa sopimuskuljetusta',
  BasicInformation: 'Perustiedot',
  ChooseOptionalRoute:
    'Valitse reitti jonka mukaan laskutetaan (vapaaehtoinen)',
  StartAddressInformation: 'Noutopaikan tiedot',
  RecipientInformation: 'Vastaanottajan tiedot',
  WeekendExtrasForCourier: 'Viikonloppulisät kuskeille',
  SaturdayExtraForCourier: 'Lauantailisä kuskille (ei sis. alv)',
  SundayExtraForCourier: 'Sunnuntailisä kuskille (ei sis. alv)',
  ChangeLinkedScheduledOrder: 'Vaihda merkittyä reittiä',
  ChangeOrderPrice: 'Muokkaa tilauksen hintaa',
  WeeklySchedule: 'Viikottaiset kuljetukset',
  FreeSchedule: 'Muut kuljetukset',
  OrderCreateTime: 'Tilauksen luontiaika',
  OrderAllocationMinutes: 'Noutoaika (Minuuttia luontiajasta)',
  OrderPickupLatest:
    'Hälytysaika jos ei noudettu (Minuuttia luontiajasta, oletus 45 min)',
  Courier: 'Kuljettaja',
  WhoEver: 'Kuka tahansa',
  mo: 'Maanantai',
  tu: 'Tiistai',
  we: 'Keskiviikko',
  th: 'Torstai',
  fr: 'Perjantai',
  sa: 'Lauantai',
  su: 'Sunnuntai',
  SpecialOrderSchedule: 'Sopimuskuljetusten aikataulu',
  ChooseSpecialOrder: 'Valitse sopimuskuljetus',
  Add: 'Lisää',
  ChangeOrderAddress: 'Vaihda toimitusosoite',
  RecreateOrder: 'Luo tilaus uudelleen',
  ReturnOrder: 'Kaksisuuntainen kuljetus',
  SearchCompany: 'Haku: Syötä y-tunnus, yrityksen nimi tai kaupunki',
  SkipSignature: 'Älä vaadi allekirjoitusta toimitettaessa',
  Merchant: 'Kauppiasyritys',
  MerchantPaymentsFromOrders: 'Laskutettavaa tilauksista',
  MerchantPaymentsFromScheduledEvents:
    'Laskutettavaa säännöllisistä kuljetuksista',
  CourierPaymentsFromOrders: 'Maksettavaa tilauksista',
  CourierPaymentsFromScheduledEvents:
    'Maksettavaa säännöllisistä kuljetuksista',
  ProfitFromOrders: 'Kate tilauksista',
  ProfitFromScheduledEvents: 'Kate säännöllisistä kuljetuksista',
  DeleteScheduledOrder: 'Poista reitti',
  ConfirmDeleteScheduledOrder:
    'Vahvista reitin poistaminen lopullisesti. Tätä toimintoa ei voi perua.',
  PriorityOrder: 'Priority kuljetus',
  RequireTwoPhotos:
    'Vaadi kuva lähetyksestä noudettaessa ja toimitettaessa',
  WeeklyTemplate: 'Viikottainen suunnitelma',
  ViaAddress: 'Väliosoite',
  AddressNotes: 'Saapumisohjeet',
  AddViaAddress: 'Uusi väliosoite',
  ViaAddresses: 'Väliosoitteet',
  ViaAddressType: 'Pysähdyksen tyyppi',
  Pickup: 'Nouto',
  Delivery: 'Toimitus',
  MustHaveBrokerAsWellIfHasEInvoiceAddress:
    'Myös verkkolaskuoperaattori tulee olla valittuna aina kun yrityksellä on verkkolaskuosoite!',
  PickupTime: 'Noutoaika',
  DeliveryTimestamp: 'Toimitusaika',
  NoTrackingForRecipient: 'Ei seurantalinkkiä vastaanottajalle',
  OtherCosts: 'Muut kulut',
  CarTransferOrders: 'Siirtoajot',
  ConfirmRemoveOrder: 'Vahvista tilauksen poistaminen',
  NewCarTransferOrder: 'Merkitse uusi siirtoajo',
  ChooseCourier: 'Valitse kuljettaja',
  Locale: 'fi',
  Timezone: 'Europe/Helsinki',
  TimeFromPickupToDelivery: 'Aikaa noudosta toimitukseen (min)',
  TimeFromCourierChosenToDelivery:
    'Aikaa kuljettajan hyväksynnästä toimitukseen (min)',
  TransportCompanyHistory: 'Kuljetusyrityskohtainen historia',
  NumPackages: 'Pakettien määrä',
  Country: 'Maa',
  SkipPincodeVerification: 'Älä kysy PIN-koodia',
  ServiceLevel: 'Tilauksen tyyppi',
  serviceLevelsameday: 'Saman päivän toimitus / 4h',
  serviceLevelsamehour: 'Pikatoimitus / 2h',
  Currency: 'Valuutta',
  MonthlyPayment: 'Kuukausimaksu',
  MyxlineUserID: 'Myxline käyttäjä ID',
  ExportCompaniesAsCsv: 'Vie yritykset csv-tiedostoon',
  UseMXLBusDeliveries: 'Käytä MXL Bussitoimituksia',
};

export default fi;
