/* eslint-disable radix */
import React from 'react';
import Modal from 'lib/Modal';
import Gateway from '../web/gateway';
import { commonStyles } from 'lib/commonStyles';

const TestPushNotificationPopup = ({
  closePopup,
}: {
  closePopup: () => void;
}): JSX.Element => {
  const [fields, changeFields] = React.useState({
    courierCompanyBusinessId: '',
    courierEmail: '',
    title: '',
    body: '',
  });

  const setVal = (key: string, val: string): void => {
    changeFields({
      ...fields,
      [key]: val,
    });
  };

  const saveData = (): void => {
    if (
      !fields.courierCompanyBusinessId ||
      !fields.courierEmail ||
      !fields.title ||
      !fields.body
    ) {
      return;
    }
    Gateway.post('/admin/send-test-push-notification', {
      data: fields,
    });

    closePopup();
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslfsdda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>New Test Push Notification</h2>
          <div className="form-group">
            <label htmlFor="testnotificationpopup-courierCompanyBusinessId">
              Courier company business ID
            </label>
            <input
              type="text"
              value={fields.courierCompanyBusinessId}
              className="form-control"
              id="testnotificationpopup-courierCompanyBusinessId"
              maxLength={100}
              onChange={(e): void =>
                setVal('courierCompanyBusinessId', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="testnotificationpopup-courierEmail">
              Courier email
            </label>
            <input
              type="text"
              value={fields.courierEmail}
              className="form-control"
              id="testnotificationpopup-courierEmail"
              onChange={(e): void =>
                setVal('courierEmail', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="testnotificationpopup-title">
              Notification Title
            </label>
            <input
              type="text"
              value={fields.title}
              className="form-control"
              id="testnotificationpopup-title"
              onChange={(e): void => setVal('title', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="testnotificationpopup-body">
              Notification Body
            </label>
            <input
              type="text"
              value={fields.body}
              className="form-control"
              id="testnotificationpopup-body"
              onChange={(e): void => setVal('body', e.target.value)}
            />
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveData}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp; Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TestPushNotificationPopup;
