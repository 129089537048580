import React from 'react';
import FixedTable from 'lib/FixedTable';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import TransportCompanyPopup from './TransportCompanyPopup';
import { TransportCompany } from './types';
import { transl } from '../translations';

import Gateway from '../web/gateway';

const getMainCompany = (
  companies: TransportCompany[]
): TransportCompany | null => {
  return (
    companies.find((attrs) => attrs.isMainTransportCompany) || null
  );
};

type TransportCompanyListItemProps = {
  attrs: TransportCompany;
  openPopup: (data: TransportCompany) => void;
  key: any;
};

const TransportCompanyListItem = ({
  attrs,
  openPopup,
}: TransportCompanyListItemProps): JSX.Element => (
  <tr
    onClick={(): void => openPopup(attrs)}
    style={{ cursor: 'pointer' }}
  >
    <td>{attrs.businessID}</td>
    <td>{attrs.name}</td>
    <td>
      {attrs.address}, {attrs.postalCode}
    </td>
  </tr>
);

const TransportCompaniesView = (): JSX.Element => {
  const [companies, setCompanies] = React.useState(
    null as TransportCompany[] | null
  );
  const [companyPopupData, setCompanyPopupData] = React.useState(
    null as TransportCompany | null
  );

  React.useEffect(() => {
    Gateway.get('/admin/transportcompanies').then((data) =>
      setCompanies(data.data)
    );
    return (): void => {
      // Intentionally empty
    };
  }, []);

  const reloadCompanies = (): void => {
    Gateway.get('/admin/transportcompanies').then((data) =>
      setCompanies(data.data)
    );
  };

  const mainTransportCompany = getMainCompany(companies || []);

  const openNewCompanyPopup = (): void => {
    setCompanyPopupData({
      businessID: '',
      name: '',
      address: '',
      postalCode: '',
      city: '',
      country: 'FI',
      language: 'fi',
      iban: '',
      bic: '',
      isMainTransportCompany: false,
      contactPersonEmail: '',
      shouldSkipAutomatedPayments: false,
    });
  };

  const closeCompanyPopup = (): void => {
    setCompanyPopupData(null);
  };

  if (companies === null) {
    return <LoadingComponent />;
  }
  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={openNewCompanyPopup}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;{transl('NewTransportCompany')}
        </button>
      </div>
      <div style={commonStyles.defaultContainer}>
        <FixedTable
          header={
            <tr>
              <th>{transl('BusinessID')}</th>
              <th>{transl('Name')}</th>
              <th>{transl('Address')}</th>
            </tr>
          }
        >
          {companies.map((attrs, i) => {
            return (
              <TransportCompanyListItem
                key={i}
                openPopup={setCompanyPopupData}
                attrs={attrs}
              />
            );
          })}
        </FixedTable>
      </div>
      {companyPopupData !== null && (
        <TransportCompanyPopup
          closePopup={closeCompanyPopup}
          reloadCompanies={reloadCompanies}
          attrs={companyPopupData}
          mainTransportCompanyFound={!!mainTransportCompany}
        />
      )}
    </div>
  );
};

export default TransportCompaniesView;
