/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { formatFinnishPhoneNo } from 'lib/helpers';
import Gateway from '../web/gateway';

export type SpecialOrderViaAddressWithoutCoords = {
  title: string;
  address: string;
  postalCode: string;
  notes: string;
  phone: string;
  type: 'pickup' | 'delivery';
};

export type SpecialOrderViaAddress =
  SpecialOrderViaAddressWithoutCoords & {
    lat: number;
    long: number;
  };

export type WeeklyScheduleItem = {
  courierBase32: string | null;
  orderCreationHour: number;
  orderCreationMinute: number;
  timeToPickupMinutes: number | null;
  timeUntilEarliestPickupTimeMinutes: number | null;
};

export type SpecialOrderWeeklySchedule = {
  mo?: WeeklyScheduleItem[];
  tu?: WeeklyScheduleItem[];
  we?: WeeklyScheduleItem[];
  th?: WeeklyScheduleItem[];
  fr?: WeeklyScheduleItem[];
  sa?: WeeklyScheduleItem[];
  su?: WeeklyScheduleItem[];
  exceptionDates?: string[];
  timezone?: 'Europe/Helsinki';
};

export type ScheduleItem = {
  courierBase32: string | null;
  orderCreationTimestamp: string;
  timeToPickupMinutes: number | null;
  timeUntilEarliestPickupTimeMinutes: number | null;
};

export type SpecialOrder = {
  id?: string;
  name: string;
  providerCompanyBusinessID: string | null;
  courierPrice: number;
  merchantPrice: number;
  courierSaturdayExtra: number;
  courierSundayExtra: number;
  merchantSundayExtra: number;
  linkedScheduledOrderID: string | null;
  orderCreateFields: {
    allowLeaveAtDoor: boolean;
    backupAddress: string | null;
    backupAddressPostalCode: string | null;
    backupPhone: string | null;
    city: string;
    externalID: string;
    numPackages: number;
    providerCompanyBusinessID: string;
    providerCompanyName: string;
    recipientAddress: string;
    recipientAddressNotes: string;
    recipientEmail: string;
    recipientName: string;
    recipientPhoneNo: string;
    recipientPostalCode: string;
    startAddress: string;
    startAddressNotes: string;
    startAddressPhone: string;
    startAddressTitle: string;
    startPostalCode: string;
    currency: string;
  };
  weeklySchedule: SpecialOrderWeeklySchedule;
  weeklyTemplate: SpecialOrderWeeklySchedule;
  schedule: ScheduleItem[];
  returnToStartAfterComplete: boolean;
  deliveryConfirmationCanBeSkipped: boolean;
  requirePhotoAtStartAndEnd: boolean;
  priorityOrder: boolean;
  noTrackingForRecipient: boolean;
  timezone: string;
  viaAddresses: SpecialOrderViaAddress[] | null;
  skipPincodeVerification: boolean;
  myxlineUserID: string | null;
};

export type OrderCreationData = {
  startAddressTitle: string;
  startAddress: string;
  startPostalCode: string;
  startAddressNotes: string;
  startAddressPhone: string;
  recipientName: string;
  recipientAddress: string;
  recipientPostalCode: string;
  recipientPhoneNo: string;
  recipientEmail: string;
  recipientAddressNotes: string;
};

export const serializeSum = (price: string): number => {
  return Math.round(
    parseFloat(price.trim().split(',').join('.')) * 100
  );
};

export const deserializeSum = (cents: number | null): string => {
  if (cents === null) return '';
  return (cents / 100.0).toFixed(2);
};

export const orderModificationDataValid = (
  providerCompanyBusinessID: string,
  name: string,
  createOrderFields: OrderCreationData,
  courierPrice: string,
  merchantPrice: string
): boolean => {
  if (
    isNaN(serializeSum(courierPrice)) ||
    isNaN(serializeSum(merchantPrice))
  ) {
    return false;
  }
  if (!name || !providerCompanyBusinessID) return false;

  if (
    !createOrderFields.startAddressTitle ||
    !createOrderFields.startAddress ||
    !createOrderFields.startPostalCode ||
    !createOrderFields.startAddressPhone ||
    !createOrderFields.recipientName ||
    !createOrderFields.recipientAddress ||
    !createOrderFields.recipientPostalCode ||
    !createOrderFields.recipientPhoneNo ||
    !createOrderFields.recipientEmail
  ) {
    return false;
  }

  return true;
};

const serializeSpecialOrder = (
  providerCompanyBusinessID: string,
  name: string,
  createOrderFields: OrderCreationData,
  courierPrice: string,
  merchantPrice: string,
  linkedScheduledOrderID: string | null,
  courierSaturdayExtra: string,
  courierSundayExtra: string,
  merchantSundayExtra: string,
  returnToStartAfterComplete: boolean,
  deliveryConfirmationCanBeSkipped: boolean,
  requirePhotoAtStartAndEnd: boolean,
  priorityOrder: boolean,
  noTrackingForRecipient: boolean,
  viaAddresses: SpecialOrderViaAddressWithoutCoords[] | null,
  skipPincodeVerification: boolean,
  currency: string,
  myxlineUserID: string | null
) => {
  return {
    providerCompanyBusinessID,
    name,
    courierPrice: serializeSum(courierPrice),
    merchantPrice: serializeSum(merchantPrice),
    courierSaturdayExtra: serializeSum(courierSaturdayExtra) || 0,
    courierSundayExtra: serializeSum(courierSundayExtra) || 0,
    merchantSundayExtra: serializeSum(merchantSundayExtra) || 0,
    linkedScheduledOrderID,
    orderCreateFields: {
      ...createOrderFields,
      externalID: name
        .toLowerCase()
        .split(' ')
        .join('')
        .split('ä')
        .join('a')
        .split('ö')
        .join('a'),
      backupAddress: null,
      backupAddressPostalCode: null,
      backupPhone: null,
      numPackages: 1,
      startAddressPhone: formatFinnishPhoneNo(
        createOrderFields.startAddressPhone
      ),
      recipientPhoneNo: formatFinnishPhoneNo(
        createOrderFields.recipientPhoneNo
      ),
      allowLeaveAtDoor: requirePhotoAtStartAndEnd,
      startAddressNotes: createOrderFields.startAddressNotes || '-',
      recipientAddressNotes:
        createOrderFields.recipientAddressNotes || '-',
      currency,
    },
    returnToStartAfterComplete,
    deliveryConfirmationCanBeSkipped,
    requirePhotoAtStartAndEnd,
    priorityOrder,
    viaAddresses,
    noTrackingForRecipient,
    skipPincodeVerification,
    myxlineUserID,
  };
};

export const deserializeSpecialOrderCreationData = (
  attrs: SpecialOrder
): OrderCreationData => {
  return {
    startAddressTitle: attrs.orderCreateFields.startAddressTitle,
    startAddress: attrs.orderCreateFields.startAddress,
    startPostalCode: attrs.orderCreateFields.startPostalCode,
    startAddressNotes: attrs.orderCreateFields.startAddressNotes,
    startAddressPhone: attrs.orderCreateFields.startAddressPhone,
    recipientName: attrs.orderCreateFields.recipientName,
    recipientAddress: attrs.orderCreateFields.recipientAddress,
    recipientPostalCode: attrs.orderCreateFields.recipientPostalCode,
    recipientPhoneNo: attrs.orderCreateFields.recipientPhoneNo,
    recipientEmail: attrs.orderCreateFields.recipientEmail,
    recipientAddressNotes:
      attrs.orderCreateFields.recipientAddressNotes,
  };
};

export const createSpecialOrder = async (
  providerCompanyBusinessID: string,
  name: string,
  createOrderFields: OrderCreationData,
  courierPrice: string,
  merchantPrice: string,
  linkedScheduledOrderID: string | null,
  courierSaturdayExtra: string,
  courierSundayExtra: string,
  merchantSundayExtra: string,
  returnToStartAfterComplete: boolean,
  deliveryConfirmationCanBeSkipped: boolean,
  requirePhotoAtStartAndEnd: boolean,
  priorityOrder: boolean,
  noTrackingForRecipient: boolean,
  viaAddresses: SpecialOrderViaAddressWithoutCoords[] | null,
  skipPincodeVerification: boolean,
  currency: string,
  myxlineUserID: string | null
) => {
  await Gateway.post('/admin/special-orders', {
    data: serializeSpecialOrder(
      providerCompanyBusinessID,
      name,
      createOrderFields,
      courierPrice,
      merchantPrice,
      linkedScheduledOrderID,
      courierSaturdayExtra,
      courierSundayExtra,
      merchantSundayExtra,
      returnToStartAfterComplete,
      deliveryConfirmationCanBeSkipped,
      requirePhotoAtStartAndEnd,
      priorityOrder,
      noTrackingForRecipient,
      viaAddresses,
      skipPincodeVerification,
      currency,
      myxlineUserID
    ),
  });
};

export const listSpecialOrders = async (): Promise<
  SpecialOrder[]
> => {
  const res = await Gateway.get('/admin/special-orders');
  return res.data;
};

export const updateSpecialOrder = async (
  id: string,
  providerCompanyBusinessID: string,
  name: string,
  createOrderFields: OrderCreationData,
  courierPrice: string,
  merchantPrice: string,
  linkedScheduledOrderID: string | null,
  courierSaturdayExtra: string,
  courierSundayExtra: string,
  merchantSundayExtra: string,
  returnToStartAfterComplete: boolean,
  deliveryConfirmationCanBeSkipped: boolean,
  requirePhotoAtStartAndEnd: boolean,
  priorityOrder: boolean,
  noTrackingForRecipient: boolean,
  viaAddresses: SpecialOrderViaAddressWithoutCoords[] | null,
  skipPincodeVerification: boolean,
  currency: string,
  myxlineUserID: string | null
) => {
  await Gateway.put(`/admin/special-orders/id/${id}/edit`, {
    data: serializeSpecialOrder(
      providerCompanyBusinessID,
      name,
      createOrderFields,
      courierPrice,
      merchantPrice,
      linkedScheduledOrderID,
      courierSaturdayExtra,
      courierSundayExtra,
      merchantSundayExtra,
      returnToStartAfterComplete,
      deliveryConfirmationCanBeSkipped,
      requirePhotoAtStartAndEnd,
      priorityOrder,
      noTrackingForRecipient,
      viaAddresses,
      skipPincodeVerification,
      currency,
      myxlineUserID
    ),
  });
};

export const updateWeeklySchedule = async (
  specialOrderId: string,
  weeklySchedule: SpecialOrderWeeklySchedule
) => {
  await Gateway.put(
    `/admin/special-orders/id/${specialOrderId}/weekly-schedule`,
    {
      data: {
        weeklySchedule,
      },
    }
  );
};

export const updateSchedule = async (
  specialOrderId: string,
  schedule: ScheduleItem[]
) => {
  await Gateway.put(
    `/admin/special-orders/id/${specialOrderId}/schedule`,
    {
      data: {
        schedule,
      },
    }
  );
};

export const updateWeeklyTemplate = async (
  specialOrderId: string,
  weeklyTemplate: SpecialOrderWeeklySchedule
) => {
  await Gateway.put(
    `/admin/special-orders/id/${specialOrderId}/weekly-template`,
    {
      data: {
        weeklyTemplate,
      },
    }
  );
};

export const deserializeViaAddresses = (
  inp: SpecialOrderViaAddress[] | null
): SpecialOrderViaAddressWithoutCoords[] | null => {
  if (!inp) return null;
  return inp.map((item) => ({
    title: item.title,
    address: item.address,
    postalCode: item.postalCode,
    notes: item.notes,
    phone: item.phone,
    type: item.type,
  }));
};

export const filterValidViaAddresses = (
  inp: SpecialOrderViaAddressWithoutCoords[] | null
): SpecialOrderViaAddressWithoutCoords[] | null => {
  if (!inp) return null;
  const filtered = inp.filter(
    (item) =>
      !!item.title &&
      !!item.address &&
      !!item.postalCode &&
      !!item.phone
  );
  if (filtered.length === 0) return null;
  return filtered.map((attrs) => ({
    ...attrs,
    phone: formatFinnishPhoneNo(attrs.phone),
  }));
};
