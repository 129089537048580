/* eslint-disable radix */
import React from 'react';
import Modal from 'lib/Modal';
import Gateway from '../web/gateway';
import { commonStyles } from 'lib/commonStyles';

export const TestNoCouriersAlertPopup = ({
  closePopup,
}: {
  closePopup: () => void;
}): JSX.Element => {
  const [city, setCity] = React.useState('helsinki');
  const [type, setType] = React.useState('rejectedOrders');

  const sendAlert = (): void => {
    if (!city) {
      return;
    }
    Gateway.post('/admin/send-test-no-couriers-alert', {
      data: { city, type },
    });

    closePopup();
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslfsdda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>Send test alert</h2>
          <div className="form-group">
            <label htmlFor="testnocouriersalertpopup-city">
              City
            </label>
            <input
              type="text"
              value={city}
              className="form-control"
              id="testnocouriersalertpopup-city"
              onChange={(e): void => setCity(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="testnocouriersalertpopup-type">
              Type
            </label>
            <input
              type="text"
              value={type}
              className="form-control"
              id="testnocouriersalertpopup-type"
              onChange={(e): void => setType(e.target.value)}
            />
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={sendAlert}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp; Send
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
