/* eslint-disable radix */
import React from 'react';
import Modal from 'lib/Modal';
import Gateway from '../web/gateway';
import { commonStyles } from 'lib/commonStyles';

export const TestInvoiceBreakdownPopup = ({
  closePopup,
}: {
  closePopup: () => void;
}): JSX.Element => {
  const [
    providerCompanyBusinessId,
    setProviderCompanyBusinessId,
  ] = React.useState('');

  const sendData = (): void => {
    Gateway.post('/admin/send-test-invoice-summary', {
      data: { providerCompanyBusinessId },
    });

    closePopup();
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslfsdda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>Send test invoice breakdown</h2>
          <div className="form-group">
            <label htmlFor="testinvoicebreakdownpopup-providerCompanyBusinessId">
              Business ID
            </label>
            <input
              type="text"
              value={providerCompanyBusinessId}
              className="form-control"
              id="testinvoicebreakdownpopup-providerCompanyBusinessId"
              onChange={(e): void =>
                setProviderCompanyBusinessId(e.target.value)
              }
            />
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={sendData}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp; Send
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
