import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import TransportCompanySelector from './TransportCompanySelector';
import TransportCompanyAdminsListView from './TransportCompanyAdminsListView';
import { transl } from '../translations';

const TransportCompanyAdminsView = (): JSX.Element => {
  const [
    chosenCompanyBusinessId,
    setChosenCompanyBusinessId,
  ] = React.useState(null as string | null);

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <label>{transl('ChooseTransportCompany')}</label>
        <TransportCompanySelector
          onChange={setChosenCompanyBusinessId}
        />
      </div>
      {chosenCompanyBusinessId !== null && (
        <TransportCompanyAdminsListView
          businessId={chosenCompanyBusinessId}
        />
      )}
    </div>
  );
};

export default TransportCompanyAdminsView;
