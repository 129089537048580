import React from 'react';
import { LoadingComponent } from 'lib/LoadingComponent';

import Gateway from '../web/gateway';
import { transl } from '../translations';

export const ProviderCompanyInvoicesView = (): JSX.Element | null => {
  const [
    canGenerateInvoices,
    setCanGenerateInvoices,
  ] = React.useState(false);
  const [allOrdersVerified, setAllOrdersVerified] = React.useState(
    false
  );

  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    Gateway.get('/admin/can-generate-invoices').then((res) => {
      setCanGenerateInvoices(res.data.canGenerateInvoices);
      setLoading(false);
    });
  }, []);

  const generateInvoices = (): void => {
    if (!window.confirm(transl('ConfirmCreateInvoices'))) return;
    setLoading(true);
    Gateway.post('/admin/generate-payments-and-invoices', {})
      .then(() => {
        setLoading(false);
        alert(transl('InvoicesSuccessfullyCreated'));
      })
      .catch(() => {
        setLoading(false);
        alert(transl('InvoiceCreationUnsuccessful'));
      });
  };

  if (loading) {
    return <LoadingComponent />;
  }

  if (!canGenerateInvoices) {
    return (
      <div style={{ padding: '15px' }}>
        <h2>{transl('NoInvoicesToCreateAtThisTime')}</h2>
      </div>
    );
  }
  return (
    <div style={{ padding: '15px' }}>
      <h2>{transl('CreateInvoicesForThisDay')}</h2>
      <div className="form-group">
        <label>
          <input
            type="checkbox"
            checked={allOrdersVerified}
            onChange={(e): void =>
              setAllOrdersVerified((e.target as any).checked)
            }
          />
          &nbsp;&nbsp;{transl('ConfirmAllOrdersAreChecked')}
        </label>
      </div>
      <div>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={generateInvoices}
          disabled={!allOrdersVerified}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;{transl('CreateInvoices')}
        </button>
      </div>
    </div>
  );
};
