import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import Spinner from 'lib/Spinner';
import moment from 'moment';

import {
  SpecialOrder,
  ScheduleItem,
  updateSchedule,
} from '../specialOrders/requests';
import { transl } from '../translations';
import { TransportCourier } from '../scheduledOrderEvents/types';
import { dynamoKeysFromBase32 } from 'lib/helpers';
import { AddScheduleItemForm } from './schedule/AddScheduleItemForm';

import Gateway from '../web/gateway';

export const ScheduleView = ({
  order,
  reloadOrders,
}: {
  order: SpecialOrder;
  reloadOrders: () => Promise<any>;
}): JSX.Element => {
  const [schedule, setSchedule] = React.useState(
    order.schedule.filter((item) => {
      const hourAgo = moment().subtract(1, 'hours');
      return moment(item.orderCreationTimestamp).isAfter(hourAgo);
    })
  );
  const [couriers, setCouriers] = React.useState(
    [] as TransportCourier[]
  );
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    Gateway.get('/admin/transport-couriers').then((data) =>
      setCouriers(data.data)
    );
    return (): void => {
      // Intentionally empty
    };
  }, []);

  React.useEffect(() => {
    setSchedule(
      order.schedule.filter((item) => {
        const hourAgo = moment().subtract(1, 'hours');
        return moment(item.orderCreationTimestamp).isAfter(hourAgo);
      })
    );
    return (): void => {
      // Intentionally empty
    };
  }, [order]);

  const addScheduleItem = async (
    item: ScheduleItem
  ): Promise<void> => {
    setLoading(true);
    const s = schedule;
    s.push(item);
    s.sort((a, b) => {
      return moment(a.orderCreationTimestamp).isAfter(
        b.orderCreationTimestamp
      )
        ? 1
        : -1;
    });
    setSchedule(s);
    try {
      await updateSchedule(order.id || '', s);
      await reloadOrders();
    } finally {
      setLoading(false);
    }
  };

  const removeScheduleItem = async (
    item: ScheduleItem
  ): Promise<void> => {
    setLoading(true);
    let s = schedule;
    s = s.filter(
      (attrs) =>
        attrs.orderCreationTimestamp !== item.orderCreationTimestamp
    );
    setSchedule(s);
    try {
      await updateSchedule(order.id || '', s);
      await reloadOrders();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={commonStyles.defaultContainer}>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>{transl('OrderCreateTime')}</th>
            <th>{transl('OrderAllocationMinutes')}</th>
            <th>{transl('Courier')}</th>
            <th>{transl('Remove')}</th>
          </tr>
        </thead>
        <tbody>
          {schedule.map((scheduleItem, i) => (
            <tr key={i}>
              <td>
                {moment(scheduleItem.orderCreationTimestamp).format(
                  'L'
                )}{' '}
                {moment(scheduleItem.orderCreationTimestamp).format(
                  'HH:mm'
                )}
              </td>
              <td>
                {`${
                  scheduleItem.timeUntilEarliestPickupTimeMinutes ||
                  ''
                }`}
              </td>
              <td>
                {scheduleItem.courierBase32
                  ? dynamoKeysFromBase32(scheduleItem.courierBase32)
                      ?.rangeKey
                  : '-'}
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-danger btn-md"
                  onClick={(): Promise<void> =>
                    removeScheduleItem(scheduleItem)
                  }
                >
                  &nbsp;&nbsp;{transl('Remove')}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <AddScheduleItemForm
        couriers={couriers}
        addScheduleItem={addScheduleItem}
      />
      <Spinner visible={loading} />
    </div>
  );
};
