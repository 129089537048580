/* eslint-disable radix */
import React from 'react';
import Modal from 'lib/Modal';
import Gateway from '../web/gateway';
import { commonStyles } from 'lib/commonStyles';
import { transl } from '../translations';

const TransportCompanyAdminPopup = ({
  closePopup,
  reloadAdmins,
  businessId,
}: {
  closePopup: () => void;
  reloadAdmins: () => void;
  businessId: string;
}): JSX.Element => {
  const [email, changeEmail] = React.useState('');

  const saveData = (): void => {
    Gateway.post(
      `/admin/transportcompanies/businessId/${businessId}/companyadmins`,
      {
        data: {
          companyBusinessID: businessId,
          email,
        },
      }
    ).then(reloadAdmins);

    closePopup();
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: '400px',
      maxHeight: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('InviteNewCompanyAmin')}</h2>
          <div className="form-group">
            <label htmlFor="newtransportcompanyadminpopup-email">
              {transl('Email')}
            </label>
            <input
              type="text"
              value={email}
              className="form-control"
              id="newtransportcompanyadminpopup-email"
              maxLength={50}
              onChange={(e): void =>
                changeEmail(e.target.value.toLowerCase())
              }
            />
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveData}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp;{transl('SendInvite')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TransportCompanyAdminPopup;
