import React from 'react';
import Header from './Header';
import { commonStyles } from 'lib/commonStyles';
import TransportCompaniesView from './transportCompanies/TransportCompaniesView';
import TransportCompanyAdminsView from './transportCompanyAdmins/TransportCompanyAdminsView';
import ProviderCompaniesView from './providerCompanies/ProviderCompaniesView';
import DebuggingView from './debugging/DebuggingView';
import { ProviderCompanyUsersView } from './providerCompanyUsers/ProviderCompanyUsersView';
import { SpecialOrdersView } from './specialOrders/SpecialOrdersView';
import { SpecialOrderScheduleWrapperView } from './specialOrderSchedule/SpecialOrderScheduleWrapperView';
import { ProviderCompanyInvoicesView } from './providerCompanyInvoices/ProviderCompanyInvoicesView';
import { OrderStatsView } from './orderStats/OrderStatsView';
import { transl } from './translations';
import SpinnerContainer from 'lib/SpinnerContainer';
import { ProviderCompanyHistoryView } from './providerCompanyHistory/ProviderCompanyHistoryView';
import { TransportCompanyHistoryView } from './transportCompanyHistory/TransportCompanyHistoryView';
import { CarTransferOrdersView } from './carTransferOrders/CarTransferOrdersView';

const MainView = (): JSX.Element => {
  const [chosenView, setChosenView] = React.useState(
    'providercompanies'
  );
  return (
    <div style={commonStyles.flexColumnContainer}>
      <Header />
      <div style={styles.main}>
        <div className="btn-group">
          <button
            type="button"
            onClick={(): void => setChosenView('providercompanies')}
            className={
              'btn btn-primary' +
              (chosenView === 'providercompanies' ? ' active' : '')
            }
          >
            {transl('Merchants')}
          </button>
          <button
            type="button"
            onClick={(): void =>
              setChosenView('providercompanyusers')
            }
            className={
              'btn btn-primary' +
              (chosenView === 'providercompanyusers' ? ' active' : '')
            }
          >
            {transl('MerchantUsers')}
          </button>
          <button
            type="button"
            onClick={(): void => setChosenView('companies')}
            className={
              'btn btn-primary' +
              (chosenView === 'companies' ? ' active' : '')
            }
          >
            {transl('TransportCompanies')}
          </button>
          <button
            type="button"
            onClick={(): void => setChosenView('transportadmins')}
            className={
              'btn btn-primary' +
              (chosenView === 'transportadmins' ? ' active' : '')
            }
          >
            {transl('TransportCompanyAdmins')}
          </button>
          <button
            type="button"
            onClick={(): void => setChosenView('specialOrders')}
            className={
              'btn btn-primary' +
              (chosenView === 'specialOrders' ? ' active' : '')
            }
          >
            {transl('SpecialOrders')}
          </button>
          <button
            type="button"
            onClick={(): void =>
              setChosenView('specialOrderSchedule')
            }
            className={
              'btn btn-primary' +
              (chosenView === 'specialOrderSchedule' ? ' active' : '')
            }
          >
            {transl('SpecialOrderSchedule')}
          </button>
          <button
            type="button"
            onClick={(): void => setChosenView('carTransferOrders')}
            className={
              'btn btn-primary' +
              (chosenView === 'carTransferOrders' ? ' active' : '')
            }
          >
            {transl('CarTransferOrders')}
          </button>
          <button
            type="button"
            onClick={(): void => setChosenView('invoicing')}
            className={
              'btn btn-primary' +
              (chosenView === 'invoicing' ? ' active' : '')
            }
          >
            {transl('CreateNewInvoices')}
          </button>
          <button
            type="button"
            onClick={(): void => setChosenView('providerHistory')}
            className={
              'btn btn-primary' +
              (chosenView === 'providerHistory' ? ' active' : '')
            }
          >
            {transl('ProviderCompanyHistory')}
          </button>
          <button
            type="button"
            onClick={(): void =>
              setChosenView('transportCompanyHistory')
            }
            className={
              'btn btn-primary' +
              (chosenView === 'transportCompanyHistory'
                ? ' active'
                : '')
            }
          >
            {transl('TransportCompanyHistory')}
          </button>
          <button
            type="button"
            onClick={(): void => setChosenView('orderStats')}
            className={
              'btn btn-primary' +
              (chosenView === 'orderStats' ? ' active' : '')
            }
          >
            {transl('OrderStats')}
          </button>
          <button
            type="button"
            onClick={(): void => setChosenView('debugging')}
            className={
              'btn btn-primary' +
              (chosenView === 'debugging' ? ' active' : '')
            }
          >
            Debugging
          </button>
        </div>
        <div style={commonStyles.flexColumnContainer}>
          {chosenView === 'companies' && <TransportCompaniesView />}
          {chosenView === 'transportadmins' && (
            <TransportCompanyAdminsView />
          )}
          {chosenView === 'providercompanies' && (
            <ProviderCompaniesView />
          )}
          {chosenView === 'providercompanyusers' && (
            <ProviderCompanyUsersView />
          )}
          {chosenView === 'invoicing' && (
            <ProviderCompanyInvoicesView />
          )}
          {chosenView === 'providerHistory' && (
            <ProviderCompanyHistoryView />
          )}
          {chosenView === 'orderStats' && <OrderStatsView />}
          {chosenView === 'debugging' && <DebuggingView />}
          {chosenView === 'specialOrders' && <SpecialOrdersView />}
          {chosenView === 'specialOrderSchedule' && (
            <SpecialOrderScheduleWrapperView />
          )}
          {chosenView === 'carTransferOrders' && (
            <CarTransferOrdersView />
          )}
          {chosenView === 'transportCompanyHistory' && (
            <TransportCompanyHistoryView />
          )}
        </div>
      </div>
      <SpinnerContainer />
    </div>
  );
};

const styles = {
  main: {
    ...commonStyles.flexColumnContainer,
    padding: '10px',
  },
};

export default MainView;
