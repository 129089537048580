import React from 'react';
import moment from 'lib/moment';
import { commonStyles } from 'lib/commonStyles';
import { DateSearchBox } from 'lib/DateSearchBox';
import FixedTable from 'lib/FixedTable';
import { LoadingComponent } from 'lib/LoadingComponent';

import { CreateRetroactiveOrderPopup } from './CreateRetroactiveOrderPopup';
import { transl } from '../translations';
import Gateway from '../web/gateway';
import { OrderStats } from './types';

const formatSum = (cents: number): string =>
  (cents / 100.0).toFixed(2).split('.').join(',');

export const OrderStatsView = (): JSX.Element => {
  const [orderCreationPopupOpen, setOrderCreationPopupOpen] =
    React.useState(false);
  const [startDate, startDateChanged] = React.useState(
    moment().subtract(7, 'days').format('YYYY-MM-DD')
  );
  const [endDate, endDateChanged] = React.useState(
    moment().format('YYYY-MM-DD')
  );
  const [stats, setStats] = React.useState<OrderStats | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const searchPressed = (): void => {
    setLoading(true);
    Gateway.get(`admin/order-stats?start=${startDate}&end=${endDate}`)
      .then((res) => {
        setLoading(false);
        setStats(res.data);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const dates = !stats ? null : Object.keys(stats);
  if (dates) {
    dates.sort((a, b) => a.localeCompare(b));
  }

  return (
    <div style={commonStyles.flexColumnContainer}>
      <DateSearchBox
        startDate={startDate}
        endDate={endDate}
        startDateChanged={startDateChanged}
        endDateChanged={endDateChanged}
        searchPressed={searchPressed}
      />
      <div style={commonStyles.defaultContainer}>
        <div style={commonStyles.flexColumnContainer}>
          {loading && <LoadingComponent />}
          {stats && dates && !loading && (
            <FixedTable
              header={
                <tr>
                  <th>{transl('Date')}</th>
                  <th>{transl('NumberOfOrders')}</th>
                  <th>{transl('MerchantPaymentsFromOrders')}</th>
                  <th>{transl('CourierPaymentsFromOrders')}</th>
                  <th>{transl('ProfitFromOrders')}</th>
                  <th>{transl('NumberOfScheludedOrderEvents')}</th>
                  <th>
                    {transl('MerchantPaymentsFromScheduledEvents')}
                  </th>
                  <th>
                    {transl('CourierPaymentsFromScheduledEvents')}
                  </th>
                  <th>{transl('ProfitFromScheduledEvents')}</th>
                  <th>{transl('PaymentFromMerchant')}</th>
                  <th>{transl('PaymentToCourier')}</th>
                  <th>{transl('Profit')}</th>
                </tr>
              }
            >
              {dates.map((date, i) => {
                const data = stats[date];
                return (
                  <tr key={i}>
                    <td>{moment(date).format('L')}</td>
                    <td>
                      <b>{data.orders}</b>
                    </td>
                    <td>
                      {formatSum(data.merchantPaymentsFromOrders)}
                    </td>
                    <td>
                      {formatSum(data.courierPaymentsFromOrders)}
                    </td>
                    <td>
                      <b
                        style={{
                          color:
                            data.merchantPaymentsFromOrders -
                              data.courierPaymentsFromOrders <
                            0
                              ? 'red'
                              : 'black',
                        }}
                      >
                        {formatSum(
                          data.merchantPaymentsFromOrders -
                            data.courierPaymentsFromOrders
                        )}
                      </b>
                    </td>
                    <td>
                      <b>{data.scheduledOrderEvents}</b>
                    </td>
                    <td>
                      {formatSum(data.merchantPaymentsFromSchedule)}
                    </td>
                    <td>
                      {formatSum(data.courierPaymentsFromSchedule)}
                    </td>
                    <td>
                      <b
                        style={{
                          color:
                            data.merchantPaymentsFromSchedule -
                              data.courierPaymentsFromSchedule <
                            0
                              ? 'red'
                              : 'black',
                        }}
                      >
                        {formatSum(
                          data.merchantPaymentsFromSchedule -
                            data.courierPaymentsFromSchedule
                        )}
                      </b>
                    </td>
                    <td>{formatSum(data.merchantPayments)}</td>
                    <td>{formatSum(data.courierPayments)}</td>
                    <td>
                      <b
                        style={{
                          color:
                            data.merchantPayments -
                              data.courierPayments <
                            0
                              ? 'red'
                              : 'black',
                        }}
                      >
                        {formatSum(
                          data.merchantPayments - data.courierPayments
                        )}
                      </b>
                    </td>
                  </tr>
                );
              })}
            </FixedTable>
          )}
        </div>
        <div style={{ paddingTop: '15px', width: '200px' }}>
          <button
            type="button"
            className="btn btn-success btn-lg"
            onClick={(): void => setOrderCreationPopupOpen(true)}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
            &nbsp;{transl('CreateOrderRetroactively')}
          </button>
        </div>
      </div>
      {orderCreationPopupOpen && (
        <CreateRetroactiveOrderPopup
          closePopup={(): void => setOrderCreationPopupOpen(false)}
        />
      )}
    </div>
  );
};
