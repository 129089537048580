import React from 'react';
import Colors from 'lib/colors';
import { commonStyles } from 'lib/commonStyles';
import { LoadingComponent } from 'lib/LoadingComponent';
import { IS_TEST_SERVER } from './web/constants';

import { makeUnauthenticatedRequest } from './web/gateway';
import { transl } from './translations';

type Props = {
  logUserIn: (authToken: string) => void;
};

const LoginView = ({ logUserIn }: Props): JSX.Element => {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const tokenFromUrl = window.location.hash
      .split('#')
      .join('')
      .split('/')
      .join('');
    const tokenFromStore = localStorage.getItem('authToken');
    const token = tokenFromUrl || tokenFromStore;

    if (token) {
      makeUnauthenticatedRequest('POST', '/admin/auth', {
        data: { token },
      })
        .then((res) => {
          logUserIn(res.data.token);
        })
        .catch(() => {
          localStorage.removeItem('authToken');
          setLoading(false);
        });
    } else {
      setLoading(false);
    }

    window.location.hash = '';

    return (): void => {
      // Intentionally empty
    };
  }, [logUserIn]);

  if (loading) {
    return (
      <div style={{ margin: 'auto', marginTop: 200 }}>
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div
      style={{
        ...commonStyles.flexColumnContainer,
        ...styles.container,
      }}
    >
      <div style={styles.main}>
        <h1 style={commonStyles.textCenter}>
          {transl('LoginToAdminPanel')}
        </h1>
        <a
          href={`https://sso.cabapp.fi/admin/login?loginflow=${
            IS_TEST_SERVER ? 'testadmin' : 'admin'
          }`}
          className="btn btn-default btn-lg btn-block"
        >
          {transl('LoginUsingGoogle')}
        </a>
        <br />
      </div>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#ddd',
    alignItems: 'center',
    justifyContent: 'center',
  },
  main: {
    borderRadius: '20px',
    borderWidth: '1px',
    borderColor: Colors.main,
    borderStyle: 'solid',
    backgroundColor: '#eee',
    padding: '20px',
    width: '95%',
    maxWidth: '400px',
  },
};

export default LoginView;
