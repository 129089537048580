import React from 'react';
import FixedTable from 'lib/FixedTable';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import TransportCompanyAdminPopup from './TransportCompanyAdminPopup';
import { TransportCompanyAdmin } from './types';
import { dynamoKeysToBase32 } from 'lib/helpers';

import Gateway from '../web/gateway';
import { transl } from '../translations';

type TransportCompanyAdminListItemProps = {
  attrs: TransportCompanyAdmin;
  key: any;
  reloadUsers: () => any;
};

const TransportCompanyAdminListItem = ({
  attrs,
  reloadUsers,
}: TransportCompanyAdminListItemProps): JSX.Element => {
  const resetCredentials = (): void => {
    if (
      !window.confirm(
        transl('ConfirmResetPassword')
          .split('#EMAIL#')
          .join(attrs.email)
      )
    )
      return;
    Gateway.post(
      `/admin/transportcompanies/businessId/${
        attrs.companyBusinessID
      }/companyadmins/id/${dynamoKeysToBase32(
        attrs.companyBusinessID,
        attrs.email
      )}/reset`,
      {}
    ).then(() =>
      alert(
        transl('UserCredentialsReset')
          .split('#EMAIL#')
          .join(attrs.email)
      )
    );
  };
  const deleteUser = (): void => {
    if (
      !window.confirm(
        transl('ConfirmRemoveUser').split('#EMAIL#').join(attrs.email)
      )
    )
      return;
    Gateway.del(
      `/admin/transportcompanies/businessId/${
        attrs.companyBusinessID
      }/companyadmins/id/${dynamoKeysToBase32(
        attrs.companyBusinessID,
        attrs.email
      )}`
    ).then(() => {
      reloadUsers();
      alert(
        transl('UserCredentialsRemoved')
          .split('#EMAIL#')
          .join(attrs.email)
      );
    });
  };

  return (
    <tr style={{ cursor: 'pointer' }}>
      <td>{attrs.email}</td>
      <td>
        <button
          type="button"
          className="btn btn-warning btn-md"
          onClick={resetCredentials}
        >
          &nbsp;&nbsp;{transl('ResetPassword')}
        </button>
      </td>
      <td>
        <button
          type="button"
          className="btn btn-danger btn-md"
          onClick={deleteUser}
        >
          &nbsp;&nbsp;{transl('DeleteUser')}
        </button>
      </td>
    </tr>
  );
};

const TransportOrdersView = ({
  businessId,
}: {
  businessId: string;
}): JSX.Element => {
  const [admins, setAdmins] = React.useState(
    null as TransportCompanyAdmin[] | null
  );
  const [newAdminPopupOpen, setNewAdminPopupOpen] =
    React.useState(false);

  React.useEffect(() => {
    setAdmins(null);
    Gateway.get(
      `/admin/transportcompanies/businessId/${businessId}/companyadmins`
    ).then((data) => setAdmins(data.data));
    return (): void => {
      // Intentionally empty
    };
  }, [businessId]);

  const reloadAdmins = (): void => {
    setAdmins(null);
    Gateway.get(
      `/admin/transportcompanies/businessId/${businessId}/companyadmins`
    ).then((data) => setAdmins(data.data));
  };

  const openNewAdminPopup = (): void => {
    setNewAdminPopupOpen(true);
  };

  const closeCompanyPopup = (): void => {
    setNewAdminPopupOpen(false);
  };

  if (admins === null) {
    return <LoadingComponent />;
  }
  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={openNewAdminPopup}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;{transl('InviteNewUser')}
        </button>
      </div>
      <div style={commonStyles.defaultContainer}>
        <FixedTable
          header={
            <tr>
              <th>{transl('Email')}</th>
              <th>{transl('ResetPassword')}</th>
              <th>{transl('DeleteUser')}</th>
            </tr>
          }
        >
          {admins.map((attrs, i) => {
            return (
              <TransportCompanyAdminListItem
                key={i}
                attrs={attrs}
                reloadUsers={reloadAdmins}
              />
            );
          })}
        </FixedTable>
      </div>
      {newAdminPopupOpen && (
        <TransportCompanyAdminPopup
          closePopup={closeCompanyPopup}
          reloadAdmins={reloadAdmins}
          businessId={businessId}
        />
      )}
    </div>
  );
};

export default TransportOrdersView;
