/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import { transl } from '../translations';
import {
  SpecialOrder,
  listSpecialOrders,
} from '../specialOrders/requests';
import Select from 'react-virtualized-select';
import { LoadingComponent } from 'lib/LoadingComponent';
import { SpecialOrderScheduleView } from './SpecialOrderScheduleView';

export const SpecialOrderScheduleWrapperView = (): JSX.Element => {
  const [chosenScheduledOrder, setChosenScheduledOrder] =
    React.useState(null as SpecialOrder | null);
  const [orders, setOrders] = React.useState(
    null as SpecialOrder[] | null
  );

  React.useEffect(() => {
    listSpecialOrders().then(setOrders);
  }, []);

  const reloadOrders = async (): Promise<void> => {
    const os = await listSpecialOrders();
    setOrders(os);
  };

  const selectionChanged = (newSelection: any): void => {
    if (!orders) return;
    setChosenScheduledOrder(
      orders.find((item) => item.id === newSelection.value) || null
    );
  };

  if (!orders) {
    return <LoadingComponent />;
  }

  const options = orders.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const selectedOption = !!chosenScheduledOrder
    ? {
        label: chosenScheduledOrder.name,
        value: chosenScheduledOrder.id,
      }
    : null;

  const chosenOrderWithAllFieldsUpToDate = chosenScheduledOrder
    ? orders.find((it) => it.id === chosenScheduledOrder.id)
    : null;

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <label>{transl('ChooseSpecialOrder')}</label>
        <Select
          options={options}
          onChange={selectionChanged}
          // @ts-ignore
          value={selectedOption}
        />
      </div>
      {!!chosenOrderWithAllFieldsUpToDate && (
        <SpecialOrderScheduleView
          order={chosenOrderWithAllFieldsUpToDate}
          reloadOrders={reloadOrders}
        />
      )}
    </div>
  );
};
