/* eslint-disable radix */
import React from 'react';
import Modal from 'lib/Modal';
import Gateway from '../web/gateway';
import { commonStyles } from 'lib/commonStyles';

export const RecalculateMerchantPricesPopup = ({
  closePopup,
}: {
  closePopup: () => void;
}): JSX.Element => {
  const [businessID, setbusinessID] = React.useState('');
  const [startDate, setstartDate] = React.useState('');
  const [endDate, setendDate] = React.useState('');

  const sendData = (): void => {
    Gateway.post('/admin/recalculate-merchant-prices', {
      data: { businessID, startDate, endDate },
    });

    closePopup();
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslfsdda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>Recalculate prices for a merchant</h2>
          <div className="form-group">
            <label htmlFor="recalmerchpairce-businessID">
              Business ID
            </label>
            <input
              type="text"
              value={businessID}
              className="form-control"
              id="recalmerchpairce-businessID"
              onChange={(e): void => setbusinessID(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="recalmerchpairce-startDate">
              Start date (ISO-8601)
            </label>
            <input
              type="text"
              value={startDate}
              className="form-control"
              id="recalmerchpairce-startDate"
              onChange={(e): void => setstartDate(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="recalmerchpairce-endDate">
              End date (ISO-8601)
            </label>
            <input
              type="text"
              value={endDate}
              className="form-control"
              id="recalmerchpairce-endDate"
              onChange={(e): void => setendDate(e.target.value)}
            />
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={sendData}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp; Send
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
