import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import { WeeklyScheduleItem } from '../../specialOrders/requests';
import { transl } from '../../translations';
import { AddTemplateItemForm } from './AddTemplateItemForm';

export const DayTemplateView = ({
  title,
  schedule,
  addScheduleItem,
  removeScheduleItem,
}: {
  title: string;
  schedule: WeeklyScheduleItem[];
  addScheduleItem: (item: WeeklyScheduleItem) => any;
  removeScheduleItem: (item: WeeklyScheduleItem) => any;
  key: any;
}): JSX.Element => {
  return (
    <div style={commonStyles.defaultContainer}>
      <div style={{ padding: '15px', border: '1px solid black' }}>
        <h4>{title}</h4>
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>{transl('OrderCreateTime')}</th>
              <th>{transl('OrderAllocationMinutes')}</th>
              <th>{transl('Remove')}</th>
            </tr>
          </thead>
          <tbody>
            {schedule.map((scheduleItem, i) => (
              <tr key={i}>
                <td>{`${scheduleItem.orderCreationHour}:${scheduleItem.orderCreationMinute}`}</td>
                <td>
                  {`${
                    scheduleItem.timeUntilEarliestPickupTimeMinutes ||
                    ''
                  }`}
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-danger btn-md"
                    onClick={(): void =>
                      removeScheduleItem(scheduleItem)
                    }
                  >
                    &nbsp;&nbsp;{transl('Remove')}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <AddTemplateItemForm addScheduleItem={addScheduleItem} />
      </div>
    </div>
  );
};
