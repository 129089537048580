import React from 'react';
import FixedTable from 'lib/FixedTable';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import moment from 'lib/moment';
import { DateSearchBox } from 'lib/DateSearchBox';
import { ProviderCompanySelector } from '../providerCompanyUsers/ProviderCompanySelector';
import { transl } from '../translations';
import { downloadStringAsFile } from './stringToFile';
import { PriceChangePopup } from './PriceChangePopup';
import { AddressChangePopup } from './AddressChangePopup';

import Gateway from '../web/gateway';

const formatTimestamp = (stamp: string): string =>
  moment(stamp).format('L') + ' ' + moment(stamp).format('HH:mm');

const formatAddress = (
  title: string,
  shortAddress: string,
  postalCode: string
): string => `${title} (${shortAddress}, ${postalCode})`;

const formatSum = (cents: number): string => {
  return (cents / 100)
    .toFixed(2)
    .split('.')
    .join(transl('DecimalSeparator'));
};

type HistoryListItemProps = {
  attrs: any;
  key: any;
  openPriceChangePopup: () => any;
  openAddressChangePopup: () => any;
  recreateOrder: () => any;
};

const HistoryListItem = ({
  attrs,
  openPriceChangePopup,
  openAddressChangePopup,
  recreateOrder,
}: HistoryListItemProps): JSX.Element => {
  const [recreateButtonDisabled, setRecreateButtonDisabled] =
    React.useState(false);
  return (
    <tr>
      <td>
        {attrs.externalID} ({attrs.transportOrderId})
      </td>
      <td>{transl(attrs.state)}</td>
      <td>{transl('serviceLevel' + attrs.serviceLevel)}</td>
      <td>
        {attrs.distanceInKm.toFixed(3)} (
        {attrs.actualDistanceInKm.toFixed(3)})
      </td>
      <td>{formatTimestamp(attrs.orderPlacedTimestamp)}</td>
      <td>
        {formatAddress(
          attrs.startAddressTitle,
          attrs.startAddress,
          attrs.startPostalCode
        )}
      </td>
      <td>
        {formatAddress(
          attrs.recipientName,
          attrs.recipientAddress,
          attrs.recipientPostalCode
        )}
      </td>
      <td>{attrs.numPackages}</td>
      <td>{formatSum(attrs.priceForOrderProvider)}</td>
      <td>{formatSum(attrs.priceForCourier)}</td>
      <td>
        {!!attrs.earliestPickupTime
          ? formatTimestamp(attrs.earliestPickupTime)
          : transl('Immediately')}
      </td>
      <td>
        <button
          className="btn btn-primary btn-md"
          onClick={openAddressChangePopup}
        >
          {transl('ChangeOrderAddress')}
        </button>
      </td>
      <td>
        <button
          className="btn btn-primary btn-md"
          onClick={openPriceChangePopup}
        >
          {transl('ChangeOrderPrice')}
        </button>
      </td>
      <td>
        <button
          className="btn btn-primary btn-md"
          onClick={(): void => {
            setRecreateButtonDisabled(true);
            recreateOrder();
          }}
          disabled={recreateButtonDisabled}
        >
          {transl('RecreateOrder')}
        </button>
      </td>
    </tr>
  );
};

export const ProviderCompanyHistoryView = (): JSX.Element => {
  const [providerCompanyBusinessID, setProviderCompanyBusinessID] =
    React.useState(null as string | null);

  const [startDate, setStartDate] = React.useState(
    moment().subtract(3, 'days').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  const [orders, setOrders] = React.useState([] as any[] | null);

  const [priceChangePopupData, setPriceChangePopupData] =
    React.useState(null as any);
  const [addressChangePopupData, setAddressChangePopupData] =
    React.useState(null as any);

  const doSearch = (
    businessId: string,
    start: string,
    end: string
  ): void => {
    setOrders(null);
    Gateway.get(
      `/admin/providercompanies/businessId/${businessId}/history?start=${start}&end=${end}`
    ).then((data) => setOrders(data.data));
  };

  const downloadCsv = (
    businessId: string,
    start: string,
    end: string
  ): void => {
    Gateway.get(
      `/admin/providercompanies/businessId/${businessId}/history-csv?start=${start}&end=${end}`
    ).then((data) =>
      downloadStringAsFile('data.csv', data.data.csvString)
    );
  };

  const recreateOrder = (transportOrderId: string): void => {
    Gateway.post(
      `/admin/order/${transportOrderId}/recreate-order`,
      {}
    );
  };

  const searchPressed = (): void => {
    if (providerCompanyBusinessID) {
      doSearch(providerCompanyBusinessID, startDate, endDate);
    }
  };

  const downloadCsvPressed = (): void => {
    if (providerCompanyBusinessID) {
      downloadCsv(providerCompanyBusinessID, startDate, endDate);
    }
  };

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <label>{transl('ChooseMerchant')}</label>
        <ProviderCompanySelector
          onChange={setProviderCompanyBusinessID}
        />
      </div>
      {providerCompanyBusinessID && (
        <React.Fragment>
          <DateSearchBox
            startDate={startDate}
            endDate={endDate}
            startDateChanged={setStartDate}
            endDateChanged={setEndDate}
            searchPressed={searchPressed}
          />
          {orders === null && <LoadingComponent />}
          {orders !== null && (
            <div style={commonStyles.defaultContainer}>
              <FixedTable
                header={
                  <tr>
                    <th>{transl('OrderNumber')}</th>
                    <th>{transl('State')}</th>
                    <th>{transl('ServiceLevel')}</th>
                    <th>{transl('DistanceInKm')}</th>
                    <th>{transl('OrderPlacedTimestamp')}</th>
                    <th>{transl('PickupAddress')}</th>
                    <th>{transl('RecipientName')}</th>
                    <th>{transl('NumPackages')}</th>
                    <th>{transl('PriceWithoutVat')}</th>
                    <th>{transl('PriceForCourier')}</th>
                    <th>{transl('EarliestPickupTime')}</th>
                    <th>{transl('ChangeOrderAddress')}</th>
                    <th>{transl('ChangeOrderPrice')}</th>
                    <th>{transl('RecreateOrder')}</th>
                  </tr>
                }
              >
                {orders.map((attrs, i) => {
                  return (
                    <HistoryListItem
                      key={i}
                      attrs={attrs}
                      openPriceChangePopup={(): any =>
                        setPriceChangePopupData(attrs)
                      }
                      openAddressChangePopup={(): any =>
                        setAddressChangePopupData(attrs)
                      }
                      recreateOrder={(): any =>
                        recreateOrder(attrs.transportOrderId)
                      }
                    />
                  );
                })}
              </FixedTable>
            </div>
          )}
          {orders !== null && (
            <div style={{ paddingTop: '15px', width: '200px' }}>
              <button
                type="button"
                className="btn btn-success btn-lg"
                onClick={downloadCsvPressed}
              >
                <i className="fa fa-plus" aria-hidden="true"></i>
                &nbsp;{transl('DownloadAsCsv')}
              </button>
            </div>
          )}
        </React.Fragment>
      )}
      {priceChangePopupData && (
        <PriceChangePopup
          transportOrderId={priceChangePopupData.transportOrderId}
          priceForCourier={priceChangePopupData.priceForCourier}
          priceForOrderProvider={
            priceChangePopupData.priceForOrderProvider
          }
          closePopup={(): void => setPriceChangePopupData(null)}
          reloadOrders={searchPressed}
        />
      )}
      {addressChangePopupData && (
        <AddressChangePopup
          transportOrderId={addressChangePopupData.transportOrderId}
          closePopup={(): void => setAddressChangePopupData(null)}
          reloadOrders={searchPressed}
        />
      )}
    </div>
  );
};
