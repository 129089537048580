import React from 'react';
import FixedTable from 'lib/FixedTable';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import moment from 'lib/moment';
import { DateSearchBox } from 'lib/DateSearchBox';
import { ProviderCompanySelector } from '../providerCompanyUsers/ProviderCompanySelector';
import { transl } from '../translations';
import { CarTransferOrder } from './types';
import { NewCarTransferOrderPopup } from './NewCarTransferOrderPopup';

import Gateway from '../web/gateway';

const formatTimestamp = (stamp: string): string =>
  moment(stamp).format('L') + ' ' + moment(stamp).format('HH:mm');

const formatSum = (cents: number): string => {
  return (cents / 100)
    .toFixed(2)
    .split('.')
    .join(transl('DecimalSeparator'));
};

type HistoryListItemProps = {
  attrs: CarTransferOrder;
  removeOrder: () => any;
  key: any;
};

const HistoryListItem = ({
  attrs,
  removeOrder,
}: HistoryListItemProps): JSX.Element => {
  return (
    <tr>
      <td>{attrs.providerCompanyBusinessID}</td>
      <td>{attrs.label}</td>
      <td>{formatTimestamp(attrs.timestamp)}</td>
      <td>{formatSum(attrs.priceForProvider)}</td>
      <td>{formatSum(attrs.priceForCourier)}</td>
      <td>{formatSum(attrs.otherCosts)}</td>
      <td>
        {formatSum(
          attrs.priceForProvider -
            attrs.priceForCourier -
            attrs.otherCosts
        )}
      </td>
      <td>
        <button
          className="btn btn-danger btn-md"
          onClick={removeOrder}
        >
          {transl('Remove')}
        </button>
      </td>
    </tr>
  );
};

export const CarTransferOrdersView = (): JSX.Element => {
  const [newOrderPopupOpen, setNewOrderPopupOpen] =
    React.useState(false);

  const [providerCompanyBusinessID, setProviderCompanyBusinessID] =
    React.useState(null as string | null);

  const [startDate, setStartDate] = React.useState(
    moment().subtract(3, 'days').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  const [orders, setOrders] = React.useState(
    [] as CarTransferOrder[] | null
  );

  const doSearch = (
    businessId: string,
    start: string,
    end: string
  ): void => {
    setOrders(null);
    Gateway.get(
      `/admin/providercompany/${businessId}/cartransferorders?start=${start}&end=${end}`
    ).then((data) => setOrders(data.data));
  };

  const searchPressed = (): void => {
    if (providerCompanyBusinessID) {
      doSearch(providerCompanyBusinessID, startDate, endDate);
    }
  };

  const removeOrder = (orderId: string): void => {
    if (!window.confirm('ConfirmRemoveOrder')) return;
    setOrders(null);
    Gateway.del(`/admin/cartransferorders/id/${orderId}`).then(
      searchPressed
    );
  };

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <label>{transl('ChooseMerchant')}</label>
        <ProviderCompanySelector
          onChange={setProviderCompanyBusinessID}
        />
      </div>
      {providerCompanyBusinessID && (
        <React.Fragment>
          <DateSearchBox
            startDate={startDate}
            endDate={endDate}
            startDateChanged={setStartDate}
            endDateChanged={setEndDate}
            searchPressed={searchPressed}
          />
          {orders === null && <LoadingComponent />}
          {orders !== null && (
            <div style={commonStyles.defaultContainer}>
              <FixedTable
                header={
                  <tr>
                    <th>{transl('ProviderCompanyBusinessID')}</th>
                    <th>{transl('Name')}</th>
                    <th>{transl('DeliveryTimestamp')}</th>
                    <th>{transl('PriceForMerchant')}</th>
                    <th>{transl('PriceForCourier')}</th>
                    <th>{transl('OtherCosts')}</th>
                    <th>{transl('Profit')}</th>
                    <th>{transl('Remove')}</th>
                  </tr>
                }
              >
                {orders.map((attrs, i) => {
                  return (
                    <HistoryListItem
                      key={i}
                      attrs={attrs}
                      removeOrder={(): any => {
                        if (attrs.id) removeOrder(attrs.id);
                      }}
                    />
                  );
                })}
              </FixedTable>
            </div>
          )}
          <div style={{ paddingTop: '15px', width: '200px' }}>
            <button
              type="button"
              className="btn btn-success btn-lg"
              onClick={(): any => setNewOrderPopupOpen(true)}
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              &nbsp;{transl('NewCarTransferOrder')}
            </button>
          </div>
        </React.Fragment>
      )}
      {newOrderPopupOpen && (
        <NewCarTransferOrderPopup
          closePopup={(): any => setNewOrderPopupOpen(false)}
          reloadOrders={searchPressed}
        />
      )}
    </div>
  );
};
