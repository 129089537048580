import * as base32 from 'hi-base32';

type DynamoKeys = {
  hashKey: string;
  rangeKey?: string;
};

export const dynamoKeysToBase32 = (
  hashKey: string,
  rangeKey?: string
): string => {
  const inputArray = [hashKey];
  if (rangeKey) {
    inputArray.push(rangeKey);
  }

  const jsonInput = JSON.stringify(inputArray);
  const base32Input = base32.encode(jsonInput);
  return base32Input.toLowerCase().split('=').join('1');
};

export const dynamoKeysFromBase32 = (
  input: string
): DynamoKeys | null => {
  try {
    const base32Input = input.toUpperCase().split('1').join('=');
    const jsonInput = base32.decode(base32Input);
    const parsedInput = JSON.parse(jsonInput);
    if (
      Array.isArray(parsedInput) &&
      parsedInput.length > 0 &&
      typeof parsedInput[0] === 'string'
    ) {
      if (
        parsedInput.length === 2 &&
        typeof parsedInput[1] === 'string'
      ) {
        return {
          hashKey: parsedInput[0],
          rangeKey: parsedInput[1],
        };
      }
      return {
        hashKey: parsedInput[0],
      };
    }
    return null;
  } catch (err) {
    return null;
  }
};

export const formatFinnishPhoneNo = (phone: string): string => {
  const res = phone.split(' ').join('').split('-').join('');
  if (res.startsWith('00358')) {
    return '+358' + res.substring(5);
  }
  if (res.startsWith('0')) {
    return '+358' + res.substring(1);
  } else if (res.startsWith('+') && res.length > 8) {
    return res;
  }
  throw new Error(`Invalid phone number: ${phone}`);
};
