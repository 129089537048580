import React from 'react';
import Modal from 'lib/Modal';
import { commonStyles } from 'lib/commonStyles';
import Spinner from 'lib/Spinner';

import Gateway from '../web/gateway';
import { transl } from '../translations';

const serializeSum = (price: string): number => {
  return Math.round(
    parseFloat(price.trim().split(',').join('.')) * 100
  );
};

const deserializeSum = (cents: number): string => {
  return (cents / 100.0).toFixed(2);
};

export const PriceChangePopup = ({
  closePopup,
  reloadOrders,
  transportOrderId,
  priceForCourier,
  priceForOrderProvider,
}: {
  closePopup: () => any;
  reloadOrders: () => any;
  transportOrderId: string;
  priceForCourier: number;
  priceForOrderProvider: number;
}): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const [courierPrice, setCourierPrice] = React.useState(
    deserializeSum(priceForCourier)
  );
  const [merchantPrice, setMerchantPrice] = React.useState(
    deserializeSum(priceForOrderProvider)
  );

  const saveData = (): void => {
    setLoading(true);
    Gateway.post(
      `/admin/order/${transportOrderId}/update-order-price`,
      {
        data: {
          priceForCourier: serializeSum(courierPrice),
          priceForOrderProvider: serializeSum(merchantPrice),
        },
      }
    )
      .then(() => {
        setLoading(false);
        reloadOrders();
        closePopup();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: '500px',
      maxHeight: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslytgda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>{transl('ChangeOrderPrice')}</h2>
          <div className="form-group">
            <label htmlFor="changeorderpricepopup-merchantPrice">
              {transl('PriceForMerchant')}
            </label>
            <input
              type="text"
              value={merchantPrice}
              className="form-control"
              id="changeorderpricepopup-merchantPrice"
              maxLength={100}
              onChange={(e): void => setMerchantPrice(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="changeorderpricepopup-courierPrice">
              {transl('PriceForCourier')}
            </label>
            <input
              type="text"
              value={courierPrice}
              className="form-control"
              id="changeorderpricepopup-courierPrice"
              maxLength={100}
              onChange={(e): void => setCourierPrice(e.target.value)}
            />
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveData}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp;{transl('Save')}
            </button>
          </div>
        </div>
        <Spinner visible={loading} />
      </Modal>
    </div>
  );
};
