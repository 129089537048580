import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import { transl } from '../translations';
import { SpecialOrder } from '../specialOrders/requests';
import { WeeklyScheduleView } from './WeeklyScheduleView';
import { ScheduleView } from './ScheduleView';
import { WeeklyTemplateView } from './WeeklyTemplateView';

export const SpecialOrderScheduleView = ({
  order,
  reloadOrders,
}: {
  order: SpecialOrder;
  reloadOrders: () => Promise<any>;
}): JSX.Element => {
  const [chosenView, setChosenView] = React.useState(
    'template' as 'template' | 'weekly' | 'list'
  );

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <div className="btn-group">
          <button
            type="button"
            onClick={(): void => setChosenView('template')}
            className={
              'btn btn-primary' +
              (chosenView === 'template' ? ' active' : '')
            }
          >
            {transl('WeeklyTemplate')}
          </button>
          <button
            type="button"
            onClick={(): void => setChosenView('weekly')}
            className={
              'btn btn-primary' +
              (chosenView === 'weekly' ? ' active' : '')
            }
          >
            {transl('WeeklySchedule')}
          </button>
          <button
            type="button"
            onClick={(): void => setChosenView('list')}
            className={
              'btn btn-primary' +
              (chosenView === 'list' ? ' active' : '')
            }
          >
            {transl('FreeSchedule')}
          </button>
        </div>
      </div>
      {chosenView === 'template' && (
        <WeeklyTemplateView
          order={order}
          reloadOrders={reloadOrders}
        />
      )}
      {chosenView === 'weekly' && (
        <WeeklyScheduleView
          order={order}
          reloadOrders={reloadOrders}
        />
      )}
      {chosenView === 'list' && (
        <ScheduleView order={order} reloadOrders={reloadOrders} />
      )}
    </div>
  );
};
