import React from 'react';
import { WeeklyScheduleItem } from '../../specialOrders/requests';
import { transl } from '../../translations';

export const AddTemplateItemForm = ({
  addScheduleItem,
}: {
  addScheduleItem: (item: WeeklyScheduleItem) => any;
}): JSX.Element => {
  const [orderCreationHour, setOrderCreationHour] =
    React.useState('');
  const [orderCreationMinute, setOrderCreationMinute] =
    React.useState('');
  const [
    timeUntilEarliestPickupTimeMinutes,
    setTimeUntilEarliestPickupTimeMinutes,
  ] = React.useState('');

  const validate = (): boolean => {
    const hour = parseInt(orderCreationHour, 10) || -1;
    const min = parseInt(orderCreationMinute, 10) || 0;
    return hour >= 0 && hour < 24 && min >= 0 && min < 60;
  };

  const isValid = validate();

  const savePressed = (): void => {
    if (!isValid) return;
    addScheduleItem({
      courierBase32: null,
      orderCreationHour: parseInt(orderCreationHour, 10),
      orderCreationMinute: parseInt(orderCreationMinute, 10) || 0,
      timeUntilEarliestPickupTimeMinutes:
        parseInt(timeUntilEarliestPickupTimeMinutes, 10) || null,
      timeToPickupMinutes: null,
    });
    setTimeUntilEarliestPickupTimeMinutes('');
    setOrderCreationMinute('');
    setOrderCreationHour('');
  };

  return (
    <div style={{ paddingTop: '15px' }}>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th>{transl('OrderCreateTime')}</th>
            <th>{transl('OrderAllocationMinutes')}</th>
            <th>{transl('Add')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ width: '250px' }}>
              <input
                type="text"
                value={orderCreationHour}
                className="form-control"
                id="productionscriptPopup-script"
                onChange={(e): void =>
                  setOrderCreationHour(e.target.value)
                }
                style={{ width: '100px', display: 'inline' }}
              />{' '}
              :{' '}
              <input
                type="text"
                value={orderCreationMinute}
                className="form-control"
                id="productionscriptPopup-script"
                onChange={(e): void =>
                  setOrderCreationMinute(e.target.value)
                }
                style={{ width: '100px', display: 'inline' }}
              />
            </td>
            <td>
              <input
                type="text"
                value={timeUntilEarliestPickupTimeMinutes}
                className="form-control"
                id="productionscriptPopup-script"
                onChange={(e): void =>
                  setTimeUntilEarliestPickupTimeMinutes(
                    e.target.value
                  )
                }
                style={{ width: '100px' }}
              />
            </td>
            <td>
              <button
                type="button"
                className="btn btn-primary btn-md"
                onClick={savePressed}
                disabled={!isValid}
              >
                &nbsp;&nbsp;{transl('Add')}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
