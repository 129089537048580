import React from 'react';
import { SpecialOrderViaAddressWithoutCoords } from './requests';
import { transl } from '../translations';

const ViaAddressView = ({
  attrs,
  setVal,
  remove,
  idx,
}: {
  attrs: SpecialOrderViaAddressWithoutCoords;
  setVal: (
    key: keyof SpecialOrderViaAddressWithoutCoords,
    value: any
  ) => any;
  remove: () => any;
  idx: number;
  key: number;
}): JSX.Element => {
  return (
    <div
      style={{
        paddingTop: '15px',
        paddingBottom: '15px',
        borderBottom: '1px solid black',
        borderTop: '1px solid black',
      }}
    >
      <h4>
        {transl('ViaAddress')} {idx + 1}
      </h4>
      <div className="form-group">
        <label
          htmlFor={`newspecialorderpopup-viaAddress-title-${idx}`}
        >
          {transl('Name')}*
        </label>
        <input
          type="text"
          value={attrs.title}
          className="form-control"
          id={`newspecialorderpopup-viaAddress-title-${idx}`}
          onChange={(e): void => setVal('title', e.target.value)}
        />
      </div>
      <div className="form-group">
        <label
          htmlFor={`newspecialorderpopup-viaAddress-address-${idx}`}
        >
          {transl('Address')}*
        </label>
        <input
          type="text"
          value={attrs.address}
          className="form-control"
          id={`newspecialorderpopup-viaAddress-address-${idx}`}
          onChange={(e): void => setVal('address', e.target.value)}
        />
      </div>
      <div className="form-group">
        <label
          htmlFor={`newspecialorderpopup-viaAddress-postalCode-${idx}`}
        >
          {transl('PostalCode')}*
        </label>
        <input
          type="text"
          value={attrs.postalCode}
          className="form-control"
          id={`newspecialorderpopup-viaAddress-postalCode-${idx}`}
          maxLength={5}
          onChange={(e): void => setVal('postalCode', e.target.value)}
        />
      </div>
      <div className="form-group">
        <label
          htmlFor={`newspecialorderpopup-viaAddress-phone-${idx}`}
        >
          {transl('Phone')}*
        </label>
        <input
          type="text"
          value={attrs.phone}
          className="form-control"
          id={`newspecialorderpopup-viaAddress-phone-${idx}`}
          onChange={(e): void => setVal('phone', e.target.value)}
        />
      </div>
      <div className="form-group">
        <label
          htmlFor={`newspecialorderpopup-viaAddress-notes-${idx}`}
        >
          {transl('AddressNotes')}*
        </label>
        <textarea
          value={attrs.notes}
          className="form-control"
          id={`newspecialorderpopup-viaAddress-notes-${idx}`}
          onChange={(e): void => setVal('notes', e.target.value)}
        />
      </div>
      <div className="form-group">
        <label>{transl('ViaAddressType')}</label>
        <select
          className="form-control"
          value={attrs.type}
          onChange={(e): void => setVal('type', e.target.value)}
        >
          <option value="pickup">{transl('Pickup')}</option>
          <option value="delivery">{transl('Delivery')}</option>
        </select>
      </div>
      <div className="form-group">
        <button
          type="button"
          className="btn btn-danger btn-md"
          onClick={remove}
        >
          {transl('Remove')}
        </button>
      </div>
    </div>
  );
};

export const ViaAddressesView = ({
  viaAddresses,
  setViaAddresses,
}: {
  viaAddresses: SpecialOrderViaAddressWithoutCoords[] | null;
  setViaAddresses: (
    addrees: SpecialOrderViaAddressWithoutCoords[] | null
  ) => any;
}): JSX.Element => {
  const addViaAddress = (): void => {
    const prevAddresses = viaAddresses ?? [];
    setViaAddresses([
      ...prevAddresses,
      {
        title: '',
        address: '',
        postalCode: '',
        notes: '',
        phone: '',
        type: 'pickup',
      },
    ]);
  };
  const removeViaAddressAtIdx = (idx: number): void => {
    const addresses = [...(viaAddresses ?? [])];
    addresses.splice(idx, 1);
    setViaAddresses(addresses);
  };
  const setVal = (
    idx: number,
    key: keyof SpecialOrderViaAddressWithoutCoords,
    value: any
  ): void => {
    const addresses = [...(viaAddresses ?? [])];
    addresses[idx][key] = value;
    setViaAddresses(addresses);
  };

  return (
    <div>
      <h3>{transl('ViaAddresses')}</h3>
      {viaAddresses &&
        viaAddresses.map((attrs, i) => {
          return (
            <ViaAddressView
              attrs={attrs}
              setVal={(
                key: keyof SpecialOrderViaAddressWithoutCoords,
                value: any
              ): void => setVal(i, key, value)}
              remove={(): void => removeViaAddressAtIdx(i)}
              idx={i}
              key={i}
            />
          );
        })}
      <div className="form-group">
        <button
          type="button"
          className="btn btn-primary btn-md"
          onClick={addViaAddress}
        >
          {transl('AddViaAddress')}
        </button>
      </div>
    </div>
  );
};
