import React from 'react';
import DatePicker from 'lib/DatePicker';
import moment from 'lib/moment';
import 'react-datepicker/dist/react-datepicker.css';

import { transl } from '../translations';

type Props = {
  startDate: string;
  endDate: string;
  startDateChanged: (val: string) => void;
  endDateChanged: (val: string) => void;
  searchPressed: () => void;
};

export const DateSearchBox = ({
  startDate,
  endDate,
  startDateChanged,
  endDateChanged,
  searchPressed,
}: Props): JSX.Element => {
  return (
    <div
      style={{
        padding: '15px',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <label style={{ paddingTop: '5px' }}>
        {transl('WriteSearchDates')}:&nbsp;&nbsp;
      </label>
      <DatePicker
        maxDate={moment(endDate).toDate()}
        selected={moment(startDate).toDate()}
        className="form-control"
        onChange={(val): void => {
          if (val) startDateChanged(moment(val).format('YYYY-MM-DD'));
        }}
        dateFormat={'dd.MM.yyyy'}
      />
      &nbsp;-&nbsp;
      <DatePicker
        minDate={moment(startDate).toDate()}
        selected={moment(endDate).toDate()}
        className="form-control"
        onChange={(val): void => {
          if (val) endDateChanged(moment(val).format('YYYY-MM-DD'));
        }}
        dateFormat={'dd.MM.yyyy'}
      />
      &nbsp;&nbsp;&nbsp;
      <button
        type="button"
        className="btn btn-primary btn-md"
        onClick={searchPressed}
      >
        <i className="fa fa-paper-plane"></i>&nbsp;&nbsp;
        {transl('DoSearch')}
      </button>
    </div>
  );
};
