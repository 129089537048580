/* eslint-disable radix */
import React from 'react';
import Modal from 'lib/Modal';
import Gateway from '../web/gateway';
import { commonStyles } from 'lib/commonStyles';

import { TransportCompany } from './types';
import { transl } from '../translations';

const TransportCompanyPopup = ({
  closePopup,
  reloadCompanies,
  attrs,
  mainTransportCompanyFound,
}: {
  closePopup: () => void;
  reloadCompanies: () => void;
  attrs: TransportCompany;
  mainTransportCompanyFound: boolean;
}): JSX.Element => {
  const [companyFields, changeCompanyFields] = React.useState({
    businessID: attrs.businessID,
    name: attrs.name,
    address: attrs.address,
    postalCode: attrs.postalCode,
    city: attrs.city,
    country: attrs.country,
    language: attrs.language || 'fi',
    iban: attrs.iban || '',
    bic: attrs.bic || '',
    isMainTransportCompany: attrs.isMainTransportCompany || false,
    contactPersonEmail: attrs.contactPersonEmail,
    shouldSkipAutomatedPayments: attrs.shouldSkipAutomatedPayments,
  } as TransportCompany);

  const setVal = (key: string, val: any): void => {
    changeCompanyFields({
      ...companyFields,
      [key]: val,
    });
  };

  const setBoolVal = (key: string, val: boolean): void => {
    changeCompanyFields({
      ...companyFields,
      [key]: val,
    });
  };

  const isNew = attrs.businessID.length === 0;

  const saveDisabled =
    !companyFields.businessID ||
    !companyFields.name ||
    !companyFields.contactPersonEmail ||
    !companyFields.address ||
    !companyFields.postalCode ||
    !companyFields.city ||
    !companyFields.country ||
    !companyFields.language ||
    !companyFields.iban ||
    !companyFields.bic;

  const saveData = (): void => {
    if (saveDisabled) return;
    if (isNew) {
      Gateway.post('/admin/transportcompanies', {
        data: companyFields,
      }).then(reloadCompanies);
    } else {
      Gateway.put(
        `/admin/transportcompanies/businessId/${attrs.businessID}`,
        {
          data: companyFields,
        }
      ).then(reloadCompanies);
    }

    closePopup();
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          {isNew && <h2>{transl('NewTransportCompany')}</h2>}
          {!isNew && <h2>{transl('ModifyTransportCompany')}</h2>}
          <p className="text-danger">{transl('AllFieldsRequired')}</p>
          <div className="form-group">
            <label htmlFor="newtransportcompanypopup-businessID">
              {transl('BusinessID')}
            </label>
            <input
              type="text"
              value={companyFields.businessID}
              className="form-control"
              id="newtransportcompanypopup-businessID"
              maxLength={50}
              disabled={!isNew}
              onChange={(e): void =>
                setVal('businessID', e.target.value.trim())
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newtransportcompanypopup-name">
              {transl('Name')}
            </label>
            <input
              type="text"
              value={companyFields.name}
              className="form-control"
              id="newtransportcompanypopup-name"
              maxLength={100}
              onChange={(e): void => setVal('name', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="newtransportcompanypopup-contactPersonEmail">
              {transl('CompanyEmail')}
            </label>
            <input
              type="text"
              value={companyFields.contactPersonEmail}
              className="form-control"
              id="newtransportcompanypopup-contactPersonEmail"
              maxLength={200}
              onChange={(e): void =>
                setVal('contactPersonEmail', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newtransportcompanypopup-address">
              {transl('Address')}
            </label>
            <input
              type="text"
              value={companyFields.address}
              className="form-control"
              id="newtransportcompanypopup-address"
              maxLength={200}
              onChange={(e): void =>
                setVal('address', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newtransportcompanypopup-postalCode">
              {transl('PostalCode')}
            </label>
            <input
              type="text"
              value={companyFields.postalCode}
              className="form-control"
              id="newtransportcompanypopup-postalCode"
              maxLength={50}
              onChange={(e): void =>
                setVal('postalCode', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newtransportcompanypopup-city">
              {transl('City')}
            </label>
            <input
              type="text"
              value={companyFields.city}
              className="form-control"
              id="newtransportcompanypopup-city"
              maxLength={50}
              onChange={(e): void => setVal('city', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="newtransportcompanypopup-language">
              {transl('Language')}
            </label>
            <select
              value={companyFields.language}
              className="form-control"
              id="newtransportcompanypopup-language"
              onChange={(e): void =>
                setVal('language', e.target.value)
              }
            >
              <option value="fi">Suomi</option>
              <option value="en">English</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="newtransportcompanypopup-iban">
              IBAN
            </label>
            <input
              type="text"
              value={companyFields.iban}
              className="form-control"
              id="newtransportcompanypopup-iban"
              maxLength={50}
              onChange={(e): void => setVal('iban', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="newtransportcompanypopup-bic">BIC</label>
            <input
              type="text"
              value={companyFields.bic}
              className="form-control"
              id="newtransportcompanypopup-bic"
              maxLength={50}
              onChange={(e): void => setVal('bic', e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={companyFields.isMainTransportCompany}
                onChange={(e): void =>
                  setBoolVal(
                    'isMainTransportCompany',
                    (e.target as any).checked
                  )
                }
                disabled={
                  mainTransportCompanyFound &&
                  !attrs.isMainTransportCompany
                }
              />
              &nbsp;&nbsp;{transl('AllowAdministration')}
            </label>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={companyFields.shouldSkipAutomatedPayments}
                onChange={(e): void =>
                  setBoolVal(
                    'shouldSkipAutomatedPayments',
                    (e.target as any).checked
                  )
                }
              />
              &nbsp;&nbsp;{transl('SkipAutomatedPayments')}
            </label>
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveData}
              disabled={saveDisabled}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp;{transl('Save')}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TransportCompanyPopup;
