import React from 'react';
import { commonStyles } from 'lib/commonStyles';
import TestOrderNotificationPopup from './TestOrderNotificationPopup';
import TestPushNotificationPopup from './TestPushNotificationPopup';
import TestTwilioVoipPushPopup from './TestTwilioVoipPushPopup';
import { TestNoCouriersAlertPopup } from './TestNoCouriersAlertPopup';
import { TestCourierReceiptPopup } from './TestCourierReceiptPopup';
import { TestCancelCallNotificationPopup } from './TestCancelCallNotificationPopup';
import { TestInvoiceBreakdownPopup } from './TestInvoiceBreakdownPopup';
import { RecalculateMerchantPricesPopup } from './RecalculateMerchantPricesPopup';
import { TestNetvisorRequestPopup } from './TestNetvisorRequestPopup';
import { ProductionScriptsPopup } from './ProductionScriptsPopup';

const DebuggingView = (): JSX.Element => {
  const [newNotificationPopupOpen, setNewNotificationPopupOpen] =
    React.useState(false);
  const [
    newPushNotificationPopupOpen,
    setNewPushNotificationPopupOpen,
  ] = React.useState(false);
  const [noCouriersAlertPopupOpen, setNoCouriersAlertPopupOpen] =
    React.useState(false);
  const [
    testTwilioVoipPushPopupOpen,
    setTestTwilioVoipPushPopupOpen,
  ] = React.useState(false);
  const [
    testCourierReceiptPopupOpen,
    setTestCourierReceiptPopupOpen,
  ] = React.useState(false);
  const [testCancelCallPopupOpen, setTestCancelCallPopupOpen] =
    React.useState(false);
  const [
    testInvoiceBreakdownPopupOpen,
    setTestInvoiceBreakdownPopupOpen,
  ] = React.useState(false);
  const [
    recalculateMerchantPricesPopupOpen,
    setRecalculateMerchantPricesPopupOpen,
  ] = React.useState(false);
  const [
    testNetvisorRequestPopupOpen,
    setTestNetvisorRequestPopupOpen,
  ] = React.useState(false);
  const [productionScriptsPopupOpen, setProductionScriptsPopupOpen] =
    React.useState(false);

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={(): any => setNewNotificationPopupOpen(true)}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;New Test Order Notification
        </button>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={(): any => setNewPushNotificationPopupOpen(true)}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;New Test Push Notification
        </button>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={(): any => setNoCouriersAlertPopupOpen(true)}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;New Test No Couriers Alert
        </button>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={(): any => setTestTwilioVoipPushPopupOpen(true)}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;New Twilio Voip Push
        </button>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={(): any => setTestCourierReceiptPopupOpen(true)}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;New Courier Test Receipt
        </button>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={(): any => setTestCancelCallPopupOpen(true)}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;New Cancel Call Notification
        </button>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={(): any => setTestInvoiceBreakdownPopupOpen(true)}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;New Test Invoice Summary
        </button>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={(): any =>
            setRecalculateMerchantPricesPopupOpen(true)
          }
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;Recalculate Merchant prices
        </button>
        <button
          type="button"
          className="btn btn-success btn-lg"
          onClick={(): any => setTestNetvisorRequestPopupOpen(true)}
        >
          <i className="fa fa-plus" aria-hidden="true"></i>
          &nbsp;New Test Netvisor Upsync
        </button>
        <button
          type="button"
          className="btn btn-primary btn-lg"
          onClick={(): any => setProductionScriptsPopupOpen(true)}
        >
          Run Production Script
        </button>
      </div>
      {newNotificationPopupOpen && (
        <TestOrderNotificationPopup
          closePopup={(): any => setNewNotificationPopupOpen(false)}
        />
      )}
      {newPushNotificationPopupOpen && (
        <TestPushNotificationPopup
          closePopup={(): any =>
            setNewPushNotificationPopupOpen(false)
          }
        />
      )}
      {noCouriersAlertPopupOpen && (
        <TestNoCouriersAlertPopup
          closePopup={(): any => setNoCouriersAlertPopupOpen(false)}
        />
      )}
      {testTwilioVoipPushPopupOpen && (
        <TestTwilioVoipPushPopup
          closePopup={(): any =>
            setTestTwilioVoipPushPopupOpen(false)
          }
        />
      )}
      {testCourierReceiptPopupOpen && (
        <TestCourierReceiptPopup
          closePopup={(): any =>
            setTestCourierReceiptPopupOpen(false)
          }
        />
      )}
      {testCancelCallPopupOpen && (
        <TestCancelCallNotificationPopup
          closePopup={(): any => setTestCancelCallPopupOpen(false)}
        />
      )}
      {testInvoiceBreakdownPopupOpen && (
        <TestInvoiceBreakdownPopup
          closePopup={(): any =>
            setTestInvoiceBreakdownPopupOpen(false)
          }
        />
      )}
      {recalculateMerchantPricesPopupOpen && (
        <RecalculateMerchantPricesPopup
          closePopup={(): any =>
            setRecalculateMerchantPricesPopupOpen(false)
          }
        />
      )}
      {testNetvisorRequestPopupOpen && (
        <TestNetvisorRequestPopup
          closePopup={(): any =>
            setTestNetvisorRequestPopupOpen(false)
          }
        />
      )}
      {productionScriptsPopupOpen && (
        <ProductionScriptsPopup
          closePopup={(): any => setProductionScriptsPopupOpen(false)}
        />
      )}
    </div>
  );
};

export default DebuggingView;
