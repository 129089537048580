import React from 'react';
import Modal from 'lib/Modal';
import { commonStyles } from 'lib/commonStyles';
import {
  SpecialOrder,
  createSpecialOrder,
  updateSpecialOrder,
  deserializeSpecialOrderCreationData,
  orderModificationDataValid,
  deserializeSum,
  SpecialOrderViaAddressWithoutCoords,
  deserializeViaAddresses,
  filterValidViaAddresses,
} from './requests';
import { transl } from '../translations';
import { ProviderCompanySelector } from '../providerCompanyUsers/ProviderCompanySelector';
import Spinner from 'lib/Spinner';
import { ViaAddressesView } from './ViaAddressesView';

export const SpecialOrderPopup = ({
  closePopup,
  reloadOrders,
  attrs,
}: {
  closePopup: () => void;
  reloadOrders: () => void;
  attrs: SpecialOrder;
}): JSX.Element => {
  const [loading, setLoading] = React.useState(false);

  const [orderCreationFields, changeOrderCreationFields] =
    React.useState(deserializeSpecialOrderCreationData(attrs));
  const [providerCompanyBusinessID, setProviderCompanyBusinessID] =
    React.useState(attrs.providerCompanyBusinessID);
  const [name, setName] = React.useState(attrs.name);
  const [courierPrice, setCourierPrice] = React.useState(
    deserializeSum(attrs.courierPrice)
  );
  const [merchantPrice, setMerchantPrice] = React.useState(
    deserializeSum(attrs.merchantPrice)
  );
  const [showCourierWeekendExtras, setShowCourierWeekendExtras] =
    React.useState(
      attrs.courierSaturdayExtra > 0 || attrs.courierSundayExtra > 0
    );
  const [courierSaturdayExtra, setCourierSaturdayExtra] =
    React.useState(deserializeSum(attrs.courierSaturdayExtra));
  const [courierSundayExtra, setCourierSundayExtra] = React.useState(
    deserializeSum(attrs.courierSundayExtra)
  );
  const [merchantSundayExtra, setMerchantSundayExtra] =
    React.useState(deserializeSum(attrs.merchantSundayExtra));
  const [returnToStartAfterComplete, setReturnToStartAfterComplete] =
    React.useState(attrs.returnToStartAfterComplete);
  const [
    deliveryConfirmationCanBeSkipped,
    setDeliveryConfirmationCanBeSkipped,
  ] = React.useState(attrs.deliveryConfirmationCanBeSkipped);
  const [requirePhotoAtStartAndEnd, setRequirePhotoAtStartAndEnd] =
    React.useState(attrs.requirePhotoAtStartAndEnd);
  const [priorityOrder, setPriorityOrder] = React.useState(
    attrs.priorityOrder
  );
  const [currency, setCurrency] = React.useState(
    attrs.orderCreateFields.currency
  );
  const [noTrackingForRecipient, setNoTrackingForRecipient] =
    React.useState(attrs.noTrackingForRecipient);
  const [skipPincodeVerification, setSkipPincodeVerification] =
    React.useState(attrs.skipPincodeVerification);
  const [myxlineUserID, setMyxlineUserID] = React.useState(
    attrs.myxlineUserID
  );

  const [viaAddresses, setViaAddresses] = React.useState<
    SpecialOrderViaAddressWithoutCoords[] | null
  >(deserializeViaAddresses(attrs.viaAddresses));

  const setVal = (key: string, val: string): void => {
    changeOrderCreationFields({
      ...orderCreationFields,
      [key]: val,
    });
  };

  const isNew = !attrs.id;

  const isValid = orderModificationDataValid(
    providerCompanyBusinessID || '',
    name,
    orderCreationFields,
    courierPrice,
    merchantPrice
  );

  const saveData = async (): Promise<void> => {
    if (!isValid) return;
    try {
      setLoading(true);
      if (attrs.id) {
        await updateSpecialOrder(
          attrs.id,
          providerCompanyBusinessID || '',
          name,
          orderCreationFields,
          courierPrice,
          merchantPrice,
          null,
          courierSaturdayExtra,
          courierSundayExtra,
          merchantSundayExtra,
          returnToStartAfterComplete,
          deliveryConfirmationCanBeSkipped,
          requirePhotoAtStartAndEnd,
          priorityOrder,
          noTrackingForRecipient,
          filterValidViaAddresses(viaAddresses),
          skipPincodeVerification,
          currency,
          myxlineUserID
        );
      } else {
        await createSpecialOrder(
          providerCompanyBusinessID || '',
          name,
          orderCreationFields,
          courierPrice,
          merchantPrice,
          null,
          courierSaturdayExtra,
          courierSundayExtra,
          merchantSundayExtra,
          returnToStartAfterComplete,
          deliveryConfirmationCanBeSkipped,
          requirePhotoAtStartAndEnd,
          priorityOrder,
          noTrackingForRecipient,
          filterValidViaAddresses(viaAddresses),
          skipPincodeVerification,
          currency,
          myxlineUserID
        );
      }
    } catch (err) {
      setLoading(false);
      window.alert(transl('ErrorSavingSpecialOrder'));
      return;
    }
    setLoading(false);
    reloadOrders();
    closePopup();
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslytgda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          {isNew && <h2>{transl('NewSpecialOrder')}</h2>}
          {!isNew && <h2>{transl('ModifySpecialOrder')}</h2>}
          <h3>{transl('BasicInformation')}</h3>
          {isNew && (
            <div className="form-group">
              <label>{transl('ChooseMerchant')}*</label>
              <ProviderCompanySelector
                onChange={setProviderCompanyBusinessID}
              />
            </div>
          )}
          {!isNew && (
            <div className="form-group">
              <label>{transl('ProviderCompanyBusinessID')}</label>
              <input
                type="text"
                value={providerCompanyBusinessID || ''}
                className="form-control"
                disabled={true}
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-name">
              {transl('Name')}*
            </label>
            <input
              type="text"
              value={name}
              className="form-control"
              id="newspecialorderpopup-name"
              onChange={(e): void => setName(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>{transl('Currency')}</label>
            <select
              className="form-control"
              value={currency}
              onChange={(e): void => setCurrency(e.target.value)}
            >
              <option value="EUR">EUR</option>
              <option value="SEK">SEK</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-courierPrice">
              {transl('PriceForCourier')}*
            </label>
            <input
              type="text"
              value={courierPrice}
              className="form-control"
              id="newspecialorderpopup-courierPrice"
              maxLength={100}
              onChange={(e): void => setCourierPrice(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={showCourierWeekendExtras}
                onChange={(e): void =>
                  setShowCourierWeekendExtras(
                    (e.target as any).checked
                  )
                }
              />
              &nbsp;&nbsp;
              {transl('WeekendExtrasForCourier')}
            </label>
          </div>
          {showCourierWeekendExtras && (
            <div className="form-group">
              <label htmlFor="newspecialorderpopup-courierSaturdayExtra">
                {transl('SaturdayExtraForCourier')}
              </label>
              <input
                type="text"
                value={courierSaturdayExtra}
                className="form-control"
                id="newspecialorderpopup-courierSaturdayExtra"
                maxLength={100}
                onChange={(e): void =>
                  setCourierSaturdayExtra(e.target.value)
                }
              />
            </div>
          )}
          {showCourierWeekendExtras && (
            <div className="form-group">
              <label htmlFor="newspecialorderpopup-courierSundayExtra">
                {transl('SundayExtraForCourier')}
              </label>
              <input
                type="text"
                value={courierSundayExtra}
                className="form-control"
                id="newspecialorderpopup-courierSundayExtra"
                maxLength={100}
                onChange={(e): void =>
                  setCourierSundayExtra(e.target.value)
                }
              />
            </div>
          )}
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-merchantPrice">
              {transl('PriceForMerchant')}*
            </label>
            <input
              type="text"
              value={merchantPrice}
              className="form-control"
              id="newspecialorderpopup-merchantPrice"
              maxLength={100}
              onChange={(e): void => setMerchantPrice(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-merchantSundayExtra">
              {transl('SundayExtra')}
            </label>
            <input
              type="text"
              value={merchantSundayExtra}
              className="form-control"
              id="newspecialorderpopup-merchantSundayExtra"
              maxLength={100}
              onChange={(e): void =>
                setMerchantSundayExtra(e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={returnToStartAfterComplete}
                onChange={(e): void =>
                  setReturnToStartAfterComplete(
                    (e.target as any).checked
                  )
                }
              />
              &nbsp;&nbsp;
              {transl('ReturnOrder')}
            </label>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={deliveryConfirmationCanBeSkipped}
                onChange={(e): void => {
                  const val: boolean = (e.target as any).checked;
                  setDeliveryConfirmationCanBeSkipped(val);
                  if (val) {
                    setRequirePhotoAtStartAndEnd(false);
                  }
                }}
              />
              &nbsp;&nbsp;
              {transl('SkipSignature')}
            </label>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={requirePhotoAtStartAndEnd}
                onChange={(e): void => {
                  const val: boolean = (e.target as any).checked;
                  setRequirePhotoAtStartAndEnd(val);
                  if (val) {
                    setDeliveryConfirmationCanBeSkipped(false);
                  }
                }}
              />
              &nbsp;&nbsp;
              {transl('RequireTwoPhotos')}
            </label>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={priorityOrder}
                onChange={(e): void =>
                  setPriorityOrder((e.target as any).checked)
                }
              />
              &nbsp;&nbsp;
              {transl('PriorityOrder')}
            </label>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={noTrackingForRecipient}
                onChange={(e): void =>
                  setNoTrackingForRecipient((e.target as any).checked)
                }
              />
              &nbsp;&nbsp;
              {transl('NoTrackingForRecipient')}
            </label>
          </div>
          <div className="form-group">
            <label>
              <input
                type="checkbox"
                checked={skipPincodeVerification}
                onChange={(e): void =>
                  setSkipPincodeVerification(
                    (e.target as any).checked
                  )
                }
              />
              &nbsp;&nbsp;
              {transl('SkipPincodeVerification')}
            </label>
          </div>
          <h3>{transl('StartAddressInformation')}</h3>
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-startAddressTitle">
              {transl('StartAddressTitle')}*
            </label>
            <input
              type="text"
              value={orderCreationFields.startAddressTitle}
              className="form-control"
              id="newspecialorderpopup-startAddressTitle"
              onChange={(e): void =>
                setVal('startAddressTitle', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-startAddress">
              {transl('StartAddress')}*
            </label>
            <input
              type="text"
              value={orderCreationFields.startAddress}
              className="form-control"
              id="newspecialorderpopup-startAddress"
              onChange={(e): void =>
                setVal('startAddress', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-startPostalCode">
              {transl('StartPostalCode')}*
            </label>
            <input
              type="text"
              value={orderCreationFields.startPostalCode}
              className="form-control"
              id="newspecialorderpopup-startPostalCode"
              onChange={(e): void =>
                setVal('startPostalCode', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-startAddressPhone">
              {transl('StartAddressPhone')}*
            </label>
            <input
              type="text"
              value={orderCreationFields.startAddressPhone}
              className="form-control"
              id="newspecialorderpopup-startAddressPhone"
              onChange={(e): void =>
                setVal('startAddressPhone', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-startAddressNotes">
              {transl('StartAddressNotes')}
            </label>
            <textarea
              value={orderCreationFields.startAddressNotes}
              className="form-control"
              id="newspecialorderpopup-startAddressNotes"
              onChange={(e): void =>
                setVal('startAddressNotes', e.target.value)
              }
            />
          </div>
          <ViaAddressesView
            viaAddresses={viaAddresses}
            setViaAddresses={setViaAddresses}
          />
          <h3>{transl('RecipientInformation')}</h3>
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-recipientName">
              {transl('RecipientName')}*
            </label>
            <input
              type="text"
              value={orderCreationFields.recipientName}
              className="form-control"
              id="newspecialorderpopup-recipientName"
              onChange={(e): void =>
                setVal('recipientName', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-recipientAddress">
              {transl('RecipientAddress')}*
            </label>
            <input
              type="text"
              value={orderCreationFields.recipientAddress}
              className="form-control"
              id="newspecialorderpopup-recipientAddress"
              onChange={(e): void =>
                setVal('recipientAddress', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-recipientPostalCode">
              {transl('RecipientPostalCode')}*
            </label>
            <input
              type="text"
              value={orderCreationFields.recipientPostalCode}
              className="form-control"
              id="newspecialorderpopup-recipientPostalCode"
              onChange={(e): void =>
                setVal('recipientPostalCode', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-recipientPhoneNo">
              {transl('RecipientPhoneNo')}*
            </label>
            <input
              type="text"
              value={orderCreationFields.recipientPhoneNo}
              className="form-control"
              id="newspecialorderpopup-recipientPhoneNo"
              onChange={(e): void =>
                setVal('recipientPhoneNo', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-recipientEmail">
              {transl('RecipientEmail')}*
            </label>
            <input
              type="text"
              value={orderCreationFields.recipientEmail}
              className="form-control"
              id="newspecialorderpopup-recipientEmail"
              onChange={(e): void =>
                setVal('recipientEmail', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-recipientAddressNotes">
              {transl('RecipientAddressNotes')}
            </label>
            <textarea
              value={orderCreationFields.recipientAddressNotes}
              className="form-control"
              id="newspecialorderpopup-recipientAddressNotes"
              onChange={(e): void =>
                setVal('recipientAddressNotes', e.target.value)
              }
            />
          </div>
          <h3>{transl('MyxlineUserID')}</h3>
          <div className="form-group">
            <label htmlFor="newspecialorderpopup-myxlineUserID">
              {transl('MyxlineUserID')}
            </label>
            <input
              type="text"
              value={myxlineUserID ?? ''}
              className="form-control"
              id="newspecialorderpopup-myxlineUserID"
              onChange={(e): void => setMyxlineUserID(e.target.value)}
            />
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              disabled={!isValid}
              onClick={saveData}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp;{transl('Save')}
            </button>
          </div>
        </div>
      </Modal>
      <Spinner visible={loading} />
    </div>
  );
};
