import React from 'react';
import FixedTable from 'lib/FixedTable';
import { LoadingComponent } from 'lib/LoadingComponent';
import { commonStyles } from 'lib/commonStyles';
import moment from 'lib/moment';
import { DateSearchBox } from 'lib/DateSearchBox';
import TransportCompanySelector from '../transportCompanyAdmins/TransportCompanySelector';
import { transl } from '../translations';

import Gateway from '../web/gateway';

const formatTimestamp = (stamp: string | null): string => {
  if (!stamp) return '-';
  return (
    moment(stamp).format('L') + ' ' + moment(stamp).format('HH:mm')
  );
};

const formatAddress = (
  title: string,
  shortAddress: string,
  postalCode: string
): string => `${title} (${shortAddress}, ${postalCode})`;

const formatSum = (cents: number): string => {
  return (cents / 100)
    .toFixed(2)
    .split('.')
    .join(transl('DecimalSeparator'));
};

const differenceInMinutes = (
  end: string | null,
  start: string | null
): string => {
  if (!end || !start) return '-';
  const duration = moment.duration(moment(end).diff(moment(start)));
  return Math.ceil(duration.asMinutes()).toString();
};

type HistoryListItemProps = {
  attrs: any;
  key: any;
};

const HistoryListItem = ({
  attrs,
}: HistoryListItemProps): JSX.Element => {
  return (
    <tr>
      <td>{attrs.externalID}</td>
      <td>{transl(attrs.state)}</td>
      <td>
        {formatAddress(
          attrs.startAddressTitle,
          attrs.startAddress,
          attrs.startPostalCode
        )}
      </td>
      <td>
        {formatAddress(
          attrs.recipientName,
          attrs.recipientAddress,
          attrs.recipientPostalCode
        )}
      </td>
      <td>{formatTimestamp(attrs.orderPlacedTimestamp)}</td>
      <td>{formatTimestamp(attrs.courierChosenTimestamp)}</td>
      <td>{formatTimestamp(attrs.courierPickupTimestamp)}</td>
      <td>{formatTimestamp(attrs.deliveredTimestamp)}</td>
      <td>{formatSum(attrs.priceForOrderProvider)}</td>
      <td>{formatSum(attrs.priceForCourier)}</td>
      <td>
        {attrs.distanceInKm.toFixed(3)} (
        {attrs.actualDistanceInKm.toFixed(3)})
      </td>
      <td>
        {differenceInMinutes(
          attrs.deliveredTimestamp,
          attrs.courierPickupTimestamp
        )}
      </td>
      <td>
        {differenceInMinutes(
          attrs.deliveredTimestamp,
          attrs.courierChosenTimestamp
        )}
      </td>
    </tr>
  );
};

export const TransportCompanyHistoryView = (): JSX.Element => {
  const [transportCompanyBusinessID, setTransportCompanyBusinessID] =
    React.useState(null as string | null);

  const [startDate, setStartDate] = React.useState(
    moment().subtract(3, 'days').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = React.useState(
    moment().format('YYYY-MM-DD')
  );

  const [orders, setOrders] = React.useState([] as any[] | null);

  const doSearch = (
    businessId: string,
    start: string,
    end: string
  ): void => {
    setOrders(null);
    Gateway.get(
      `/admin/transportcompanies/businessId/${businessId}/history?start=${start}&end=${end}`
    ).then((data) => setOrders(data.data));
  };

  const searchPressed = (): void => {
    if (transportCompanyBusinessID) {
      doSearch(transportCompanyBusinessID, startDate, endDate);
    }
  };

  return (
    <div style={commonStyles.flexColumnContainer}>
      <div style={{ padding: '15px' }}>
        <label>{transl('ChooseTransportCompany')}</label>
        <TransportCompanySelector
          onChange={setTransportCompanyBusinessID}
        />
      </div>
      {transportCompanyBusinessID && (
        <React.Fragment>
          <DateSearchBox
            startDate={startDate}
            endDate={endDate}
            startDateChanged={setStartDate}
            endDateChanged={setEndDate}
            searchPressed={searchPressed}
          />
          {orders === null && <LoadingComponent />}
          {orders !== null && (
            <div style={commonStyles.defaultContainer}>
              <FixedTable
                header={
                  <tr>
                    <th>{transl('OrderNumber')}</th>
                    <th>{transl('State')}</th>
                    <th>{transl('PickupAddress')}</th>
                    <th>{transl('RecipientName')}</th>
                    <th>{transl('OrderPlacedTimestamp')}</th>
                    <th>{transl('courierChosen')}</th>
                    <th>{transl('PickupTime')}</th>
                    <th>{transl('DeliveryTimestamp')}</th>
                    <th>{transl('PriceForMerchant')}</th>
                    <th>{transl('PriceForCourier')}</th>
                    <th>{transl('DistanceInKm')}</th>
                    <th>{transl('TimeFromPickupToDelivery')}</th>
                    <th>
                      {transl('TimeFromCourierChosenToDelivery')}
                    </th>
                  </tr>
                }
              >
                {orders.map((attrs, i) => {
                  return <HistoryListItem key={i} attrs={attrs} />;
                })}
              </FixedTable>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
