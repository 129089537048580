import React from 'react';
import Select from 'react-virtualized-select';
import { dynamoKeysToBase32 } from 'lib/helpers';
import { TransportCourier } from '../scheduledOrderEvents/types';
import { transl } from 'src/translations';

type SelectOption = {
  label: string;
  value: string;
};

export const CourierSelector = ({
  couriers,
  onSelect,
}: {
  couriers: TransportCourier[];
  onSelect: (courierBase32: string | null) => any;
}): JSX.Element => {
  const [chosenCourierId, setChosenCourierId] = React.useState(
    null as string | null
  );
  const courierOptions: SelectOption[] = couriers.map((courier) => ({
    label: `${courier.name} (${courier.email})`,
    value: dynamoKeysToBase32(
      courier.companyBusinessID,
      courier.email
    ),
  }));
  const courierSelectionChanged = (
    newSelection: SelectOption | null
  ): void => {
    setChosenCourierId(newSelection ? newSelection.value : null);
    onSelect(newSelection ? newSelection.value : null);
  };
  const currentCourierSelectOption = courierOptions.find(
    (item) => item.value === chosenCourierId
  );

  return (
    <Select
      placeholder={transl('WhoEver')}
      options={courierOptions}
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      onChange={courierSelectionChanged}
      value={currentCourierSelectOption}
    />
  );
};
