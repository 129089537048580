/* eslint-disable radix */
import React from 'react';
import Modal from 'lib/Modal';
import Gateway from '../web/gateway';
import { commonStyles } from 'lib/commonStyles';

const TestOrderNotificationPopup = ({
  closePopup,
}: {
  closePopup: () => void;
}): JSX.Element => {
  const [fields, changeFields] = React.useState({
    type: 'transport',
    courierCompanyBusinessId: '',
    courierEmail: '',
    distanceInKm: '',
  });

  const setVal = (key: string, val: string): void => {
    changeFields({
      ...fields,
      [key]: val,
    });
  };

  const saveData = (): void => {
    if (!fields.courierCompanyBusinessId || !fields.courierEmail) {
      return;
    }
    Gateway.post('/admin/send-test-order-notification', {
      data: {
        ...fields,
        distanceInKm: Number(fields.distanceInKm) || 0,
      },
    });

    closePopup();
  };

  const popupStyles = {
    overlay: {
      zIndex: 1500,
    },
    content: {
      height: window.innerHeight - 50,
      width: '450px',
      maxWidth: window.innerWidth - 100,
    },
  };

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={closePopup}
        style={popupStyles}
        contentLabel={'hsdjfkslda'}
        shouldCloseOnOverlayClick={true}
      >
        <div style={commonStyles.popupScrollContainer}>
          <h2>New Test Order Notification</h2>
          <div className="form-group">
            <label htmlFor="testnotificationpopup-courierCompanyBusinessId">
              Courier company business ID
            </label>
            <input
              type="text"
              value={fields.courierCompanyBusinessId}
              className="form-control"
              id="testnotificationpopup-courierCompanyBusinessId"
              maxLength={100}
              onChange={(e): void =>
                setVal('courierCompanyBusinessId', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="testnotificationpopup-courierEmail">
              Courier email
            </label>
            <input
              type="text"
              value={fields.courierEmail}
              className="form-control"
              id="testnotificationpopup-courierEmail"
              onChange={(e): void =>
                setVal('courierEmail', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="testnotificationpopup-distanceInKm">
              Distance (km)
            </label>
            <input
              placeholder={'0'}
              type="text"
              value={fields.distanceInKm}
              className="form-control"
              id="testnotificationpopup-distanceInKm"
              onChange={(e): void =>
                setVal('distanceInKm', e.target.value)
              }
            />
          </div>
          <div className="form-group">
            <label>Type</label>
            <select
              className="form-control"
              value={fields.type}
              onChange={(e): void => setVal('type', e.target.value)}
            >
              <option value="taxi">Taxi order</option>
              <option value="transport">Transport order</option>
            </select>
          </div>
        </div>
        <div style={commonStyles.popupBtnContainer}>
          <div style={commonStyles.flexColumnContainer}>
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block"
              onClick={saveData}
            >
              <i className="fa fa-floppy-o"></i>
              &nbsp;&nbsp; Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TestOrderNotificationPopup;
